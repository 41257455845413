import React, { useRef, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ResultTableOpenEndedListingHead, ResultTableOpenEndedListingBody } from '.';

import './ResultTableOpenEndedListing.scss';

export const ResultTableOpenEndedListing = ({ table, tableResult, isLoading, limit = 25, setLimit = () => {} }) => {
  const row = table.rows.list[table.rows.order[0]];
  const tableLength = tableResult?.values?.[row?.id]?.__input_raw__?.__total__?.__total__?.length ?? 0;

  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerHeight(entry.contentRect.height);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <>
      <div ref={containerRef} style={{ height: '100%' }}>
        <InfiniteScroll
          dataLength={limit}
          next={() => setLimit(limit + 50)}
          hasMore={tableLength + 1 > limit}
          loader={<></>}
          className={'result-table-container'}
          height={containerHeight - 1}
          scrollThreshold={1}
        >
          {isLoading && (
            <div className='loading-overlay'>
              <span className={'fas fa-spinner fa-spin fa-3x'}></span>
            </div>
          )}
          <div className={'result-table-listing'}>
            <ResultTableOpenEndedListingHead table={table} />
            <ResultTableOpenEndedListingBody table={table} tableResult={tableResult} />
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};
