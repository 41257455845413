import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Row, Col } from 'reactstrap';

import { SmvExportFileOptionsModal } from './SmvExportFileOptionsModal';
import './SmvExportFileButtonModal.scss';
import { useSelector } from 'react-redux';
import { stripHtml, useGetSourceElement } from 'smv-helpers';

export const ExportFileButton = ({
  toggle,
  type,
  exportHandler = null,
  exportOptions = null,
  handlerRef,
  iconOnly = false,
  renderAsButton = false,
  className = ''
}) => {
  const _toggle = () => {
    if (exportHandler || exportOptions) {
      handlerRef.current = {
        exportHandler: exportHandler,
        exportOptions: exportOptions
      };
    }
    toggle();
  };

  if (renderAsButton) {
    return (
      <Button
        id={`${iconOnly ? `tt_export-${type}` : ''}`}
        onClick={_toggle}
        className={className}
        size={'sm'}
        color={'secondary'}
        outline={true}
      >
        <i className={`${iconOnly ? 'fal' : 'fal mr-1'} fa-file-export`} />
        {!iconOnly && (
          <>
            <span>
              <FormattedMessage defaultMessage={`export ${type}`} id={`smoove.page.reports.export-${type}`} />
            </span>
          </>
        )}
        {iconOnly && (
          <UncontrolledTooltip target={`tt_export-${type}`} delay={{ hide: 200 }} autohide={false}>
            Export {type}
          </UncontrolledTooltip>
        )}
      </Button>
    );
  }

  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={_toggle}>
      <i className={`${iconOnly ? 'far' : 'fal mr-1'} fa-file-export icon-smv-blue `} />
      {!iconOnly && (
        <span>
          <FormattedMessage defaultMessage={`export ${type}`} id={`smoove.page.reports.export-${type}`} />
        </span>
      )}
    </div>
  );
};

export const ExportFileModal = ({
  isOpen,
  toggle,
  type,
  exportHandler = null,
  exportOptions = null,
  handlerRef = null,
  reportId,
  activeFilters
}) => {
  const intl = useIntl();

  const getSourceElement = useGetSourceElement();
  const surveyid = useSelector(state => state.survey?.id ?? false);
  const translations = useSelector(state => state.survey?.translations);
  const locales = useSelector(state => state.survey.locales);
  const selectedLocale = useSelector(state => state.projectSettings?.[surveyid]?.selectedLocale ?? locales.main);
  const report = useSelector(state => state.survey?.reports?.list?.[reportId] ?? null);

  const reportFilters = report?.filters ?? null;

  const [selectedLoopFilters, setSelectedLoopFilters] = useState({});

  const _exportOptions = useMemo(
    () => exportOptions ?? handlerRef?.current?.exportOptions ?? { pptx: true, xlsx: true },
    [exportOptions, handlerRef]
  );

  const [exportFileType, setExportFileType] = useState(Object.keys(_exportOptions)?.[0]);
  const [isExportFileOptionsModalOpen, setIsExportFileOptionsModalOpen] = useState(false);

  const toggleChangeLogModal = useCallback(() => {
    setIsExportFileOptionsModalOpen(b => !b);
  }, []);

  const saveHandler = useCallback(() => {
    if (exportHandler) exportHandler(exportFileType, selectedLoopFilters);
    else if (handlerRef?.current?.exportHandler) handlerRef.current.exportHandler(exportFileType, selectedLoopFilters);
    toggle();
  }, [exportHandler, handlerRef, exportFileType, toggle, selectedLoopFilters]);

  const activeFilterString =
    activeFilters && reportFilters
      ? getFilterString(activeFilters, reportFilters, getSourceElement, translations, selectedLocale)
      : '';

  return (
    <Modal className='smv-export-file-modal' isOpen={isOpen} toggle={toggle} container={'body'}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage defaultMessage={`Export ${type}`} id={`smoove.page.reports.export-${type}`} />
      </ModalHeader>
      <ModalBody>
        <>
          <h5 className='mt-2'>{intl.formatMessage({ id: 'smoove.page.reports.select-file-type' })}</h5>
          <div className='export-list'>
            {Object.keys(_exportOptions).map(_exportType => {
              if (!_exportOptions[_exportType]) return null;
              return (
                <div
                  key={`export-card-${_exportType}`}
                  className={classNames({
                    'export-list__item': true,
                    'export-list__item--active': exportFileType === _exportType
                  })}
                  onClick={() => setExportFileType(_exportType)}
                >
                  <div className={'export-list__item-icon'}>
                    <i
                      className={classNames({
                        'fas fa-2x': true,
                        'fa-file-powerpoint': _exportType === 'pptx',
                        'fa-file-image': _exportType === 'image',
                        'fa-file-pdf': _exportType === 'pdf',
                        'fa-file-spreadsheet': _exportType === 'xlsx'
                      })}
                    />
                  </div>
                  <div className={'export-list__item-label'}>
                    <FormattedMessage
                      id={`smoove.page.reports.export.modal.cards.export-as-${_exportType}`}
                      defaultMessage={`Export as ${_exportType}`}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
        {((selectedLoopFilters && Object.keys(selectedLoopFilters).length > 0) ||
          (activeFilters && Object.keys(activeFilters).length > 0)) && (
          <Row className='mt-1 mb-1'>
            <Col>
              <strong>{intl.formatMessage({ id: 'smoove.page.reports.export-options.filter-list.title' })}</strong>
            </Col>
          </Row>
        )}
        {activeFilters && Object.keys(activeFilters).length > 0 && (
          <>
            <Row className='mt-1' style={{ fontSize: '0.8em' }}>
              <Col>
                {intl.formatMessage({
                  id: `smoove.page.reports.export-options.filter-list.${
                    selectedLoopFilters && Object.keys(selectedLoopFilters).length > 0 ? 'initial-filter' : 'filter'
                  }`
                })}
              </Col>
            </Row>
            <Row className='mt-1 ml-1' style={{ fontSize: '0.8em' }}>
              <Col>{activeFilterString}</Col>
            </Row>
          </>
        )}
        {selectedLoopFilters && Object.keys(selectedLoopFilters).length > 0 && (
          <>
            <Row className='mt-1' style={{ fontSize: '0.8em' }}>
              <Col>
                {intl.formatMessage({
                  id: 'smoove.page.reports.export-options.filter-list.filter-loop'
                })}
              </Col>
            </Row>
            {Object.entries(selectedLoopFilters).map(([activeFilterId, subFilters]) => {
              const filter = reportFilters.list[activeFilterId];
              const { elementTitle, elementValues } = getSourceElement(filter);
              const filterLabel = filter?.label?.length > 0 ? filter.label : elementTitle;

              return Object.entries(subFilters).map(([subFilterId, b]) => {
                const subelement = elementValues.list?.[subFilterId];
                if (!subelement) return null;

                let subElementlabel = '';

                if (filter.sourceType === 'question') {
                  subElementlabel += stripHtml(translations[subelement.config.translations.label][selectedLocale]);
                } else if (filter.sourceType === 'sysvar' || filter.sourceType === 'calcvar') {
                  subElementlabel += stripHtml(subelement?.label);
                }

                let selectedLoopFilterLabel = '';

                if (activeFilterString !== '') {
                  selectedLoopFilterLabel = `${activeFilterString} | ${filterLabel} - ${subElementlabel}`;
                } else {
                  selectedLoopFilterLabel = `${filterLabel} - ${subElementlabel}`;
                }

                return (
                  <Row key={`${activeFilterId}-${subFilterId}`} className='mt-1 ml-1' style={{ fontSize: '0.8em' }}>
                    <Col>{selectedLoopFilterLabel}</Col>
                  </Row>
                );
              });
            })}
          </>
        )}
        {reportFilters && ['pptx', 'xlsx'].includes(exportFileType) && (
          <Button
            color='secondary'
            onClick={() => setIsExportFileOptionsModalOpen(true)}
            className='btn-export-options mt-2'
          >
            <i className='fal fa-gear'></i>
            {intl.formatMessage({ id: 'smoove.page.reports.export-options' })}
          </Button>
        )}
        {isExportFileOptionsModalOpen && (
          <SmvExportFileOptionsModal
            isOpen={isExportFileOptionsModalOpen}
            toggle={toggleChangeLogModal}
            reportFilters={reportFilters}
            selectedLoopFilters={selectedLoopFilters}
            setSelectedLoopFilters={setSelectedLoopFilters}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={saveHandler}>
          {intl.formatMessage({ id: 'smoove.page.reports.export-btn' })}
        </Button>
        <Button color={'grey'} onClick={toggle}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ExportFileButtonAndModal = ({
  type,
  exportHandler = null,
  exportOptions = null,
  renderAsButton = false,
  iconOnly = false,
  buttonClassName = null,
  reportId = null,
  activeFilters = null
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(b => !b);

  return (
    <>
      <ExportFileButton
        toggle={toggle}
        type={type}
        renderAsButton={renderAsButton}
        iconOnly={iconOnly}
        className={buttonClassName}
      />
      {isOpen && (
        <ExportFileModal
          toggle={toggle}
          isOpen={isOpen}
          type={type}
          exportHandler={exportHandler}
          exportOptions={exportOptions}
          reportId={reportId}
          activeFilters={activeFilters}
        />
      )}
    </>
  );
};

export const getFilterString = (filters, reportFilters, getSourceElement, translations, selectedLocale) => {
  let filterString = '';

  Object.entries(filters).forEach(([activeFilterId, subFilters]) => {
    const filter = reportFilters?.list[activeFilterId];
    if (!filter) return;
    const { elementTitle, elementValues } = getSourceElement(filter);
    const filterLabel = filter?.label?.length > 0 ? filter.label : elementTitle;

    Object.entries(subFilters).forEach(([subFilterId, b]) => {
      const subelement = elementValues.list?.[subFilterId];

      let subElementlabel = '';

      if (filter.sourceType === 'question') {
        subElementlabel += stripHtml(translations[subelement.config.translations.label][selectedLocale]);
      } else if (filter.sourceType === 'sysvar' || filter.sourceType === 'calcvar') {
        subElementlabel += stripHtml(subelement?.label);
      }

      filterString += `${filterLabel} - ${subElementlabel} | `;
    });
  });

  // Remove the trailing ' | ' if it exists
  if (filterString.endsWith(' | ')) {
    filterString = filterString.slice(0, -3);
  }

  return filterString;
};
