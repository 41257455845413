import produce from 'immer';

import { tablesActions } from 'smv-redux';

import { getTableElement } from '../ResultTable/helper';
import { TABLE_INITIAL_STATE } from '../frontend-state';

/**
 *
 * A asynchronous function that creates tables for all provided questions.
 *
 * @param {string} parentFolderId The id of the folder the tables should be saved in
 * @param {array} allQuestions An array of question-ids (strings)
 * @param {object} questionnaire The questionnaire containing all questions (eg the questionnaire redux-state)
 * @param {string} surveyId The Id of the survey
 * @param {function} counter A function that increments a value each time a table has been created (eg a react useState set-function)
 * @param {string} selectedColorScheme The color scheme that should be used for the tables, defaults to 'vidris'
 *
 * @returns {Promise} Returns a promise which will be resolved with an array of table-objects that were created by the backend
 */
export const asyncCreateTables = (
  parentFolderId,
  allQuestions,
  questionnaire,
  surveyId,
  counter = () => {},
  selectedColorScheme = 'vidris'
) => {
  return new Promise((resolve, reject) => {
    let _tables = [];

    const createTable = (idx, questions) => {
      const question = questionnaire.list[questions[idx]];
      const textInputQuestionTypes = ['single_input', 'multiple_input', 'single_input_text', 'multiple_input_text'];
      const isTextInputQuestion = textInputQuestionTypes.includes(question.config.qtype);

      const tableData = produce(TABLE_INITIAL_STATE, draftState => {
        const element = getTableElement(question);
        draftState.rows.order.push(element.id);
        draftState.rows.list[element.id] = element;
        draftState.name = `${question.config.varname ? question.config.varname + ' - ' : ''}${question.title ?? ''}`;
        draftState.parentId = parentFolderId;
        if (isTextInputQuestion) {
          draftState.chart.chartType = 'wordcloud';
        }
        draftState.chart.colorSchema = selectedColorScheme;
      });

      tablesActions
        .createTable(surveyId, tableData)
        .then(table => {
          if (table) {
            _tables.push(table);
            if (idx + 1 < questions.length) {
              counter(idx + 1);
              createTable(idx + 1, questions);
            } else {
              resolve(_tables);
            }
          }
        })
        .catch(err => {
          reject(err);
        });
    };
    // creating tables recursively
    createTable(0, allQuestions);
  });
};
