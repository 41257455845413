import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, Container, Input, FormGroup, Label } from 'reactstrap';
import produce from 'immer';

import { surveyActions } from 'smv-redux';

import { MediaLibrarySelectedFilesList } from './MediaLibrarySelectedFilesList';
import { MediaLibrarySortation } from './MediaLibrarySortation';
import { MediaLibraryUploadStage } from './MediaLibraryUploadStage';

import './MediaLibrary.scss';
import { MediaLibraryItemsList } from './MediaLibraryItemsList';
import { MediaLibraryEditImageProperties } from './MediaLibraryEditImageProperties';

export const MediaLibraryButton = ({ toggle }) => {
  return (
    <Button
      color={'secondary'}
      size={'sm'}
      outline={true}
      className='mr-2'
      style={{ marginTop: '1px', height: '30px' }}
      onClick={toggle}
    >
      <i className='far fa-photo-film' />
    </Button>
  );
};

const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

export const MediaLibraryModal = ({
  isOpen,
  toggle,
  writeable = true,
  selectFileCb,
  isSelectionDisabled = false,
  prevSelectedFiles = [],
  mediaItems = { list: {}, order: [] },
  slideshowSettings = null,
  allowedFileTypes = ['image', 'audio', 'video']
}) => {
  const media = useSelector(state => state.survey.media ?? {});
  const surveyid = useSelector(state => state.survey.id);

  const [errorMessage, setErrorMessage] = useState('');

  const handleSetErrorMessage = useCallback(err => {
    if (err) setErrorMessage(err.message ?? 'An error occured. Please try again or contact the administrator.');
    else setErrorMessage('');
  }, []);

  useEffect(() => {
    setErrorMessage('');
    return () => {
      setSelectedFiles([]);
      setSearchInputValue('');
      setEditImageProperties(null);
      setInternalMediaItems();
    };
  }, []);

  const intl = useIntl();

  const _media = useMemo(() => {
    if (!media) {
      // console.log('no media!');
      return [];
    }
    return produce(Object.values(media), draft => {
      draft.forEach((media, index) => {
        draft[index].local = {
          url: `${baseUrl}/${media.url}`,
          thumbnailUrl: `${baseUrl}/${media.thumbnailUrl}`
        };
        draft[index].loading = media.loading;
      });
    });
  }, [media]);

  const [selectedFiles, setSelectedFiles] = useState(
    prevSelectedFiles.map(imgid => _media?.find(el => el.id === imgid)).filter(el => el !== undefined) ?? []
  );
  const [editImageProperties, setEditImageProperties] = useState(null);
  const [internalMediaItems, setInternalMediaItems] = useState({
    list: { ...(mediaItems?.list ?? {}) },
    order: [...mediaItems?.order]
  });
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [fileTypesToShow, setFileTypesToShow] = useState(null); //null: all, audio, video, images

  const [sorting, setSorting] = useState('newestFirst'); //alphAsc, alphDesc, newestFirst, oldestFirst

  const [showAsSlideshow, setShowAsSlideshow] = useState(slideshowSettings?.isSlideshow ?? false);

  const inputFile = useRef(null);

  const handleClickUploadFileButton = () => {
    inputFile.current.click();
  };

  const handleFileUploadDialog = e => {
    let files = Object.values(e?.target?.files).map(el => {
      return el;
    });

    const _files = [...filesToUpload, ...files].filter(
      (el, index, self) => index === self.findIndex(t => t.name === el.name)
    );

    setFilesToUpload(_files ?? []);
    e.target.value = null;
  };

  const handleUploadFiles = files => {
    files.forEach(file => {
      surveyActions.uploadMediaElement(surveyid, file, handleSetErrorMessage);
    });
    setFilesToUpload([]);
  };

  const handleRemoveFilesToUpload = item => {
    setFilesToUpload(filesToUpload.filter(el => el.name !== item.name));
  };

  const handleSearchInput = useCallback(({ target }) => {
    setSearchInputValue(target.value.toLowerCase());
  }, []);

  const handleClickFile = useCallback(
    img => {
      if (selectedFiles.find(el => el.id === img.id)) {
        selectedFiles.filter(el => el.id === img.id);
        setSelectedFiles(selectedFiles.filter(el => el.id !== img.id));
      } else {
        setSelectedFiles([...selectedFiles, img]);
      }
    },
    [selectedFiles]
  );

  const handleEditImageProperties = useCallback(file => {
    setEditImageProperties(file);
  }, []);

  const handleSaveMediaItemConfig = useCallback(mediaConfig => {
    setInternalMediaItems(s =>
      produce(s, d => {
        if (!d.list?.[mediaConfig.source]) {
          d.order.push(mediaConfig.source);
        }

        d.list[mediaConfig.source] = mediaConfig;
      })
    );
  }, []);

  const handleUseFile = useCallback(() => {
    // if (selectedFiles.length <= 0) return null;
    if (mediaItems) selectFileCb(selectedFiles, showAsSlideshow, internalMediaItems);
    else selectFileCb(selectedFiles, showAsSlideshow);

    toggle();
  }, [selectedFiles, toggle, selectFileCb, showAsSlideshow, mediaItems, internalMediaItems]);

  const selectFilesButtonString = useMemo(() => {
    let str = 'Ok';
    if (selectedFiles?.length > 0) {
      str = intl.formatMessage({ id: `smoove.common.media-library.select-image` });
    }
    if (selectedFiles?.length > 1) {
      str = intl.formatMessage({ id: `smoove.common.media-library.select-images` });
    }
    return str;
  }, [selectedFiles?.length, intl]);

  const setSelectedFilesCb = useCallback(items => {
    setSelectedFiles(items);
  }, []);

  useEffect(() => {
    if (isOpen) {
      let _selectedFiles = [];
      prevSelectedFiles.map(imgid => _selectedFiles.push(_media?.find(el => el.id === imgid)));
      setSelectedFiles(_selectedFiles.filter(el => el !== undefined));
    }
    // disable next line to remove "missing dependency waring for prevSelectedFiles, sortAsc and sortDesc. Adding them would result in an endless loop!
    // eslint-disable-next-line
  }, [isOpen, _media]);

  useEffect(() => {
    if (allowedFileTypes.length === 1) {
      setFileTypesToShow(allowedFileTypes[0]);
    }
  }, [allowedFileTypes]);

  const uploadFilesDuplicates = useMemo(() => {
    const duplicates = [];
    filesToUpload.forEach(file => {
      Object.values(media).forEach(el => {
        if (el.originalName === file.name) {
          const _el = file;
          _el.id = el.id;
          duplicates.push(_el);
        }
      });
    });
    return duplicates;
  }, [media, filesToUpload]);

  const uploadFilesNew = useMemo(() => {
    return filesToUpload.filter(file => !uploadFilesDuplicates.find(el => el.name === file.name));
  }, [filesToUpload, uploadFilesDuplicates]);

  return (
    <Modal
      className='media-library-modal'
      id={'media-library-modal'}
      isOpen={isOpen}
      toggle={toggle}
      container={'body'}
      size={'xl'}
    >
      <ModalBody>
        <Container className='media-library-content-container'>
          <div className='mb-4 d-flex justify-content-between align-items-center border-bottom pb-2'>
            <h5 className='mb-0'>
              <FormattedMessage id={'smoove.common.media-library.media-library'} defaultMessage={'Media library'} />
            </h5>
            <Button onClick={handleClickUploadFileButton}>
              <FormattedMessage
                id={'smoove.common.media-library.upload-new-images'}
                defaultMessage={'Upload new files'}
              />
            </Button>
            <input
              type='file'
              // todo: specify which files are allowed and which are not
              accept='image/*,audio/*,video/*'
              id='file'
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={handleFileUploadDialog}
              multiple
            />
          </div>
          <MediaLibraryUploadStage
            filesToUpload={filesToUpload}
            handleRemoveFilesToUpload={handleRemoveFilesToUpload}
            uploadFilesNew={uploadFilesNew}
            uploadFilesDuplicates={uploadFilesDuplicates}
            handleUploadFiles={handleUploadFiles}
          />
          <div className='media-library-toolbar'>
            <div className='media-library-toolbar__searchbar'>
              <Input
                onChange={handleSearchInput}
                value={searchInputValue}
                name='Search'
                style={{ width: '50%' }}
                type='text'
                placeholder={intl.formatMessage({ id: `smoove.common.media-library.search-by-name` })}
              />
              {searchInputValue.length > 0 && (
                <Button className='ml-1' color={'danger'} size={'sm'} outline onClick={() => setSearchInputValue('')}>
                  <i className='far fa-times' />
                </Button>
              )}
            </div>
            <div className='media-library-toolbar__filters'>
              {allowedFileTypes.length === 3 && (
                <Button
                  className='mr-2'
                  size={'sm'}
                  outline={fileTypesToShow !== null}
                  onClick={() => setFileTypesToShow(null)}
                >
                  all
                </Button>
              )}
              {allowedFileTypes.includes('image') && (
                <Button
                  className='mr-2'
                  size={'sm'}
                  outline={fileTypesToShow !== 'image'}
                  onClick={() => setFileTypesToShow(fileTypesToShow === 'image' ? null : 'image')}
                >
                  <i className='far fa-image' />
                </Button>
              )}
              {allowedFileTypes.includes('audio') && (
                <Button
                  className='mr-2'
                  size={'sm'}
                  outline={fileTypesToShow !== 'audio'}
                  onClick={() => setFileTypesToShow(fileTypesToShow === 'audio' ? null : 'audio')}
                >
                  <i className='far fa-music' />
                </Button>
              )}
              {allowedFileTypes.includes('video') && (
                <Button
                  className='mr-2'
                  size={'sm'}
                  outline={fileTypesToShow !== 'video'}
                  onClick={() => setFileTypesToShow(fileTypesToShow === 'video' ? null : 'video')}
                >
                  <i className='far fa-video' />
                </Button>
              )}
              <MediaLibrarySortation setSorting={setSorting} sorting={sorting} />
            </div>
          </div>
          <MediaLibraryItemsList
            media={_media}
            selectedFiles={selectedFiles}
            isSelectionDisabled={isSelectionDisabled}
            handleClickFile={handleClickFile}
            sorting={sorting}
            fileTypesToShow={fileTypesToShow}
            searchInputValue={searchInputValue}
            allowedFileTypes={allowedFileTypes}
          />
          {errorMessage?.length > 0 && <div className='m-1 text-danger text-right'>{errorMessage}</div>}
          {selectedFiles && selectedFiles.length > 0 && (
            <>
              <MediaLibrarySelectedFilesList
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFilesCb}
                handleClickFile={handleClickFile}
                handleEditImageProperties={mediaItems ? handleEditImageProperties : () => {}}
                isOpen={isOpen}
              />
              {slideshowSettings && selectedFiles && selectedFiles.length > 1 && (
                <div>
                  <FormGroup check>
                    <Input
                      id={'showAsSlideshowCheckbox'}
                      type='checkbox'
                      checked={showAsSlideshow}
                      onChange={() => setShowAsSlideshow(b => !b)}
                    />
                    <Label for={'showAsSlideshowCheckbox'}>Show as slideshow</Label>
                  </FormGroup>
                </div>
              )}
              {editImageProperties && (
                <MediaLibraryEditImageProperties
                  image={editImageProperties}
                  mediaItem={internalMediaItems?.list?.[editImageProperties.id]}
                  handleSaveMediaItemConfig={handleSaveMediaItemConfig}
                  handleCloseMediaItemConfig={() => setEditImageProperties(null)}
                />
              )}
            </>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color='grey' onClick={toggle}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
        </Button>
        {!isSelectionDisabled && (
          <Button color={writeable ? 'primary' : 'grey'} disabled={!writeable} onClick={handleUseFile}>
            {selectFilesButtonString}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export const MediaLibraryButtonAndModal = ({
  buttonClassName = null,
  selectFileCb = () => {},
  writeable = false,
  isSelectionDisabled = false,
  slideshowSettings = null
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => {
    setIsOpen(b => !b);
  }, []);

  return (
    <>
      <MediaLibraryButton toggle={toggle} className={buttonClassName} />
      {isOpen && (
        <MediaLibraryModal
          toggle={toggle}
          isOpen={isOpen}
          selectFileCb={selectFileCb}
          isSelectionDisabled={isSelectionDisabled}
          writeable={writeable}
          slideshowSettings={slideshowSettings}
        />
      )}
    </>
  );
};
