import React from 'react';
import classNames from 'classnames';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const ResultTableChartHeadColumnHeader = ({ column, config, colSpan = 1, width = undefined }) => {
  const { showCodeValuesHead = false } = config;

  const label = useSubelementPropertyTranslation(column, null, {
    showCodeValue: showCodeValuesHead,
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const widthCombined = width && !isNaN(width) ? (colSpan === 1 ? width : width * colSpan) : undefined;

  return (
    <th
      style={widthCombined !== undefined ? { width: `${widthCombined}px` } : {}}
      colSpan={colSpan}
      className={classNames('head__column')}
    >
      <span title={label} className={'head__column_content'}>
        <span className='pr-1'>{label}</span>
      </span>
    </th>
  );
};
