import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { useIntl } from 'react-intl';
import { TextInputRow } from 'smv-components';

import './EditPage.scss';

export const EditPageButton = ({ toggle }) => {
  const intl = useIntl();

  return (
    <div className={`smv-custom-dropdown__buttons`} onClick={toggle}>
      <i className={'fal fa-edit icon-smv-blue mr-1'} />
      <span>{intl.formatMessage({ id: 'smoove.page.reports.edit-page' })}</span>
    </div>
  );
};

export const EditPageModal = ({ isOpen, toggle, page, reportsHandler }) => {
  const intl = useIntl();
  const [name, setName] = useState(page.name);

  const saveHandler = e => {
    e.preventDefault();
    if (!name) return false;

    if (page.name !== name) {
      reportsHandler.updatePage({ id: page.id, name }).then(() => {
        toggle();
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} autoFocus={false}>
      <Form onSubmit={saveHandler}>
        <ModalHeader toggle={toggle}>
          {intl.formatMessage({
            id: `smoove.page.survey.reports.form.edit-page.headline`,
            defaultMessage: 'Edit Page'
          })}
        </ModalHeader>
        <ModalBody>
          {/* <Row>
            <Col>
              {intl.formatMessage({
                id: `smoove.page.survey.reports.form.edit-page.text`,
                defaultMessage: 'Here you can edit the pages configuration, like the title, conditional display e.g.'
              })}
            </Col>
          </Row> */}
          <TextInputRow
            name={'title'}
            title={intl.formatMessage({
              id: `smoove.page.survey.reports.form.edit-page.fields.title.label`,
              defaultMessage: 'Title'
            })}
            tooltip={intl.formatMessage({
              id: `smoove.page.survey.reports.form.edit-page.fields.title.desc`,
              defaultMessage: 'The title is displayed in the selection menu or tabs.'
            })}
            value={name}
            change={e => setName(e.target.value)}
            placeholder={intl.formatMessage({
              id: `smoove.page.survey.reports.form.edit-page.fields.title.placeholder`,
              defaultMessage: 'enter title...'
            })}
            autoFocus={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={saveHandler} disabled={!name}>
            {intl.formatMessage({ id: 'smoove.common.buttons.save' })}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const EditPageButtonAndModal = ({ reportsHandler, page }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(b => !b);

  return (
    <>
      <EditPageButton toggle={toggle} />
      <EditPageModal toggle={toggle} isOpen={isOpen} page={page} reportsHandler={reportsHandler} />
    </>
  );
};
