import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useQuill } from 'react-quilljs';

// import { customAlphabet } from 'nanoid';

import { questionnaireActions } from 'smv-redux';

import './NewItem.scss';
import { replacePTags } from 'src/smoove/components/misc/helper';

const formats = ['bold', 'italic', 'underline', 'strike'];

export const NewItem = ({ question, type, placeholderText = 'Add item', renderInConfigModal = false }) => {
  const surveyid = useSelector(state => state.survey.id);
  const locale =
    useSelector(state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main) ||
    'en-US';

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [showPastingMultipleItems, setShowPastingMultipleItems] = useState(false);
  const [showAddingTooManyItemsWarning, setShowAddingTooManyItemsWarning] = useState(false);

  const saveInput = quill => {
    // position and value are calculated in backend
    if (quill) {
      if (type === 'choice') {
        const label = {
          [quill.locale]: replacePTags(quill.root.innerHTML)
        };
        questionnaireActions.addChoice(surveyid, question.id, label);
      } else if (type === 'scale') {
        const label = {
          [quill.locale]: replacePTags(quill.root.innerHTML)
        };
        questionnaireActions.addScale(surveyid, question.id, label);
      }

      quill.setContents(null);
    }
  };

  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], ['clean']],
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: function (range, context) {
            return true;
          }
        },
        linebreak: {
          key: 13,
          shiftKey: true,
          handler: function (range) {
            this.quill.insertText(range.index, '\n');
          }
        },
        enter: {
          key: 13,
          handler: function () {
            if (this.quill.getText().length > 1) {
              saveInput(this.quill);
            }
          }
        }
      }
    }
  };

  const { quillRef, quill } = useQuill({ modules, formats, theme: 'bubble', placeholder: placeholderText });

  // this is needed to pass the correct question and locale information to the qill handler
  if (quill) {
    quill.question = question;
    quill.locale = locale;
  }

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        quill.getText().length > 1 ? setIsAddButtonDisabled(false) : setIsAddButtonDisabled(true);
      });

      const handleBlur = range => {
        // hide tooltip on focus
        if (range === null) {
          quill.theme.tooltip.hide();
        }
      };

      quill.on('selection-change', handleBlur);

      return () => {
        quill.off('selection-change', handleBlur);
      };
    }
  }, [quill]);

  useEffect(() => {
    if (quill) {
      const handlePaste = e => {
        const text = e.clipboardData.getData('text/plain');
        const items = text.split(/\r?\n/).filter(Boolean);

        if (quill.getLength() === 1 && items.length > 1) {
          e.preventDefault();

          const subelements = [];

          const addItemsRec = (itemsToAdd, startIdx, fnName) => {
            const endIdx = startIdx + 100;
            const chunkedChoices = itemsToAdd.slice(startIdx, endIdx);
            questionnaireActions[fnName](surveyid, question.id, chunkedChoices).then(() => {
              if (itemsToAdd.length > endIdx) {
                addItemsRec(itemsToAdd, endIdx, fnName);
              } else {
                setShowPastingMultipleItems(false);
              }
            });
          };

          if (type === 'choice') {
            items.forEach(item => {
              subelements.push({
                [quill.locale]: item
              });
            });
            if (subelements.length > 1000) {
              setShowAddingTooManyItemsWarning(true);
            } else {
              setShowAddingTooManyItemsWarning(false);
              if (subelements.length > 100) {
                setShowPastingMultipleItems(true);
                addItemsRec(subelements, 0, 'addChoices');
              } else {
                questionnaireActions.addChoices(surveyid, question.id, subelements);

                // items.forEach(item => {
                //   subelements.push({
                //     id: customAlphabet('1234567890abcdef', 24)(),
                //     locale: quill.locale,
                //     stringtype: 'label',
                //     text: item,
                //     config: {
                //       randomize: false
              }
            }
          }
          if (type === 'scale') {
            items.forEach(item => {
              subelements.push({
                [quill.locale]: item
              });
            });
            if (subelements.length > 1000) {
              setShowAddingTooManyItemsWarning(true);
            } else {
              setShowAddingTooManyItemsWarning(false);
              if (subelements.length > 100) {
                setShowPastingMultipleItems(true);
                addItemsRec(subelements, 0, 'addScales');
              } else {
                questionnaireActions.addScales(surveyid, question.id, subelements);
              }
            }
          }
        }
      };

      quill.root.addEventListener('paste', handlePaste);
      return () => {
        quill.root.removeEventListener('paste', handlePaste);
      };
    }
  }, [quill, question.id, surveyid, type]);

  if (renderInConfigModal) {
    return (
      <>
        {showPastingMultipleItems && (
          <div className='fixed-loading-indicator'>
            <div className='mb-4'>Adding multiple items, please wait...</div>
            <div>
              <i className='fal fa-spinner fa-spin' />
            </div>
          </div>
        )}
        <div className='choiceItem choiceItem--new'>
          <div ref={quillRef} className={'choiceItem__label smoove-ql'}></div>
          <div>
            <Button
              color={'primary'}
              disabled={isAddButtonDisabled}
              className='ml-2'
              size={'sm'}
              onClick={() => saveInput(quill)}
            >
              Add
            </Button>
          </div>
        </div>
        {showAddingTooManyItemsWarning && (
          <div className='my-1 text-warning'>Trying to add too many items. You can only add 1000 items at once.</div>
        )}
      </>
    );
  }

  return (
    <>
      {showPastingMultipleItems && (
        <div className='fixed-loading-indicator'>
          <div className='mb-4'>Adding multiple items, please wait...</div>
          <div>
            <i className='fal fa-spinner fa-spin' />
          </div>
        </div>
      )}
      <div className='new-item'>
        <div ref={quillRef} className={'new-item__label smoove-ql'}></div>
        <Button
          color={'secondary'}
          className='ml-2'
          disabled={isAddButtonDisabled}
          size={'sm'}
          onClick={() => saveInput(quill)}
          style={{ padding: '4px 0.5rem 4px 0.5rem', fontSize: '0.75rem' }}
        >
          Add
        </Button>
      </div>
      {showAddingTooManyItemsWarning && (
        <div className='my-1 text-warning'>Trying to add too many items. You can only add 1000 items at once.</div>
      )}
    </>
  );
};
