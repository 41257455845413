import { produce } from 'immer';

import { reportsActionTypes, sharedReportActionTypes } from '../constants';

const INITIAL_STATE = {
  loading: false,
  list: {},
  order: []
};

export const reportsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    /**
     * @foreign action
     * sharedReportActionTypes.LOAD_REPORT_SUCCESS
     */
    case sharedReportActionTypes.LOAD_REPORT_SUCCESS:
      return produce(state, draftState => {
        const { report } = payload;
        // console.log(report, JSON.stringify(draftState.list))
        draftState.list[report.id] = report;
      });

    /**
     * LOAD_REPORT_X
     */
    case reportsActionTypes.LOAD_REPORTS_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.LOAD_REPORTS_SUCCESS:
      return produce(state, draftState => {
        draftState.loading = false;
        draftState.list = payload.reports.list;
        draftState.order = payload.reports.order;
      });
    case reportsActionTypes.LOAD_REPORTS_FAILURE:
      return produce(state, draftState => {
        draftState.loading = false;
        draftState.error = payload;
      });

    /**
     * ADD_REPORT_X
     */
    case reportsActionTypes.ADD_REPORT_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.ADD_REPORT_SUCCESS:
      return produce(state, draftState => {
        const { report } = payload;
        draftState.loading = false;
        draftState.order.push(report.id);
        draftState.list[report.id] = report;
      });
    case reportsActionTypes.ADD_REPORT_FAILURE:
      return produce(state, draftState => {
        // eslint-disable-next-line
        const { report, error } = payload;
        draftState.loading = false;
      });

    /**
     * DELETE_REPORT_X
     */
    case reportsActionTypes.DELETE_REPORT_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.DELETE_REPORT_SUCCESS:
      return produce(state, draftState => {
        // const { reportid } = payload;
        // if (draftState[projectid]?.selectedReportId === reportid) {
        //   draftState[projectid].selectedReportId = null;
        // }
        draftState.loading = false;
      });
    case reportsActionTypes.DELETE_REPORT_FAILURE:
      return produce(state, draftState => {
        draftState.loading = false;
      });

    /**
     * UPDATE_REPORT_X
     * CREATE_REPORT_PAGE_X
     * UPDATE_REPORT_PAGE_X
     */
    case reportsActionTypes.UPDATE_REPORT_REQUEST:
    case reportsActionTypes.CREATE_REPORT_PAGE_REQUEST:
    case reportsActionTypes.UPDATE_REPORT_PAGE_REQUEST:
    case reportsActionTypes.DELETE_REPORT_PAGE_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.UPDATE_REPORT_SUCCESS:
    case reportsActionTypes.CREATE_REPORT_PAGE_SUCCESS:
    case reportsActionTypes.UPDATE_REPORT_PAGE_SUCCESS:
    case reportsActionTypes.DELETE_REPORT_PAGE_SUCCESS:
      return produce(state, draftState => {
        const { report } = payload;
        draftState.loading = false;
        draftState.list[report.id] = report;
      });
    case reportsActionTypes.UPDATE_REPORT_FAILURE:
    case reportsActionTypes.CREATE_REPORT_PAGE_FAILURE:
    case reportsActionTypes.UPDATE_REPORT_PAGE_FAILURE:
    case reportsActionTypes.DELETE_REPORT_PAGE_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * UPDATE_REPORT_PAGE_STATIC_FILTER_X
     * DELETE_REPORT_PAGE_STATIC_FILTER_X
     */
    case reportsActionTypes.UPDATE_REPORT_PAGE_STATIC_FILTER_REQUEST:
    case reportsActionTypes.DELETE_REPORT_PAGE_STATIC_FILTER_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.UPDATE_REPORT_PAGE_STATIC_FILTER_SUCCESS:
    case reportsActionTypes.DELETE_REPORT_PAGE_STATIC_FILTER_SUCCESS:
      return produce(state, draftState => {
        const { reportId, pageId, page } = payload;
        draftState.loading = false;
        draftState.list[reportId].pages.list[pageId] = page;
      });
    case reportsActionTypes.UPDATE_REPORT_PAGE_STATIC_FILTER_FAILURE:
    case reportsActionTypes.DELETE_REPORT_PAGE_STATIC_FILTER_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * UPDATE_LAYOUT_X
     */
    case reportsActionTypes.UPDATE_LAYOUT_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.UPDATE_LAYOUT_SUCCESS:
      return produce(state, draftState => {
        const { reportId, pageId, layouts } = payload;
        draftState.list[reportId].pages.list[pageId].layouts = layouts;
        draftState.loading = false;
      });
    case reportsActionTypes.UPDATE_LAYOUT_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * CREATE_REPORT_PAGE_ELEMENT_X
     */
    case reportsActionTypes.CREATE_REPORT_PAGE_ELEMENT_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.CREATE_REPORT_PAGE_ELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { reportId, pageId, element, layouts } = payload;
        if (!draftState.list[reportId].pages.list[pageId].elements) {
          draftState.list[reportId].pages.list[pageId].elements = {};
        }
        if (!draftState.list[reportId].pages.list[pageId].layouts) {
          draftState.list[reportId].pages.list[pageId].layouts = {};
        }
        draftState.list[reportId].pages.list[pageId].elements[element.id] = element;
        draftState.list[reportId].pages.list[pageId].layouts = layouts;
        draftState.loading = false;
      });
    case reportsActionTypes.CREATE_REPORT_PAGE_ELEMENT_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * DELETE_REPORT_PAGE_ELEMENT_CONFIG_X
     */
    case reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { reportId, pageId, page } = payload;
        draftState.loading = false;
        draftState.list[reportId].pages.list[pageId] = page;
      });
    case reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_FAILURE:
      return produce(state, draftState => {
        // const { report, error } = payload;
        draftState.loading = false;
      });

    /**
     * CREATE_REPORT_FILTER_X
     */
    case reportsActionTypes.CREATE_REPORT_FILTER_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.CREATE_REPORT_FILTER_SUCCESS:
      return produce(state, draftState => {
        const { reportId, report } = payload;
        draftState.list[reportId] = report;
        draftState.loading = false;
      });
    case reportsActionTypes.CREATE_REPORT_FILTER_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * UPDATE_REPORT_FILTER_X
     */
    case reportsActionTypes.UPDATE_REPORT_FILTER_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.UPDATE_REPORT_FILTER_SUCCESS:
      return produce(state, draftState => {
        const { reportId, report } = payload;
        draftState.list[reportId] = report;
        draftState.loading = false;
      });
    case reportsActionTypes.UPDATE_REPORT_FILTER_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * DELETE_REPORT_FILTER_X
     */
    case reportsActionTypes.DELETE_REPORT_FILTER_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.DELETE_REPORT_FILTER_SUCCESS:
      return produce(state, draftState => {
        const { reportId, report } = payload;
        draftState.list[reportId] = report;
        draftState.loading = false;
      });
    case reportsActionTypes.DELETE_REPORT_FILTER_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * COPY_REPORT_PAGE_ELEMENT_CONFIG_X
     */
    case reportsActionTypes.COPY_REPORT_PAGE_ELEMENT_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.COPY_REPORT_PAGE_ELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { reportId, pageId, page, elementCopy } = payload;
        draftState.loading = false;
        draftState.list[reportId].pages.list[pageId].elements[elementCopy.id] = elementCopy;
        draftState.list[reportId].pages.list[pageId].layouts = page.layouts;
      });
    case reportsActionTypes.COPY_REPORT_PAGE_ELEMENT_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_X
     * UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_X
     * ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_X
     * REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_X
     * ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_X
     * REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_X
     */
    case reportsActionTypes.UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_REQUEST:
    case reportsActionTypes.UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_REQUEST:
    case reportsActionTypes.ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_REQUEST:
    case reportsActionTypes.ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_REQUEST:
    case reportsActionTypes.REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_REQUEST:
    case reportsActionTypes.REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case reportsActionTypes.UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_SUCCESS:
    case reportsActionTypes.UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_SUCCESS:
    case reportsActionTypes.ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_SUCCESS:
    case reportsActionTypes.ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_SUCCESS:
    case reportsActionTypes.REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_SUCCESS:
    case reportsActionTypes.REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_SUCCESS:
      return produce(state, draftState => {
        const { reportId, pageId, elementId, dashboardElement } = payload;
        draftState.loading = false;
        draftState.list[reportId].pages.list[pageId].elements[elementId] = dashboardElement;
      });
    case reportsActionTypes.UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_FAILURE:
    case reportsActionTypes.UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_FAILURE:
    case reportsActionTypes.ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_FAILURE:
    case reportsActionTypes.ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_FAILURE:
    case reportsActionTypes.REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_FAILURE:
    case reportsActionTypes.REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    /**
     * SAVE_SHARED_REPORT_REQUEST
     */
    case reportsActionTypes.SAVE_SHARED_REPORT_REQUEST:
      return produce(state, draftState => {});
    case reportsActionTypes.SAVE_SHARED_REPORT_SUCCESS:
      return produce(state, draftState => {
        const { reportid, accessLinks } = payload;
        draftState.list[reportid].accessLinks = accessLinks;
      });
    case reportsActionTypes.SAVE_SHARED_REPORT_FALIURE:
      return produce(state, draftState => {
        // const { report, error } = payload;
      });

    /**
     * DELETE_SHARED_REPORT_REQUEST
     */
    case reportsActionTypes.DELETE_SHARED_REPORT_REQUEST:
      return produce(state, draftState => {});
    case reportsActionTypes.DELETE_SHARED_REPORT_SUCCESS:
      return produce(state, draftState => {
        const { reportid, accessLinks } = payload;
        draftState.list[reportid].accessLinks = accessLinks;
      });
    case reportsActionTypes.DELETE_SHARED_REPORT_FALIURE:
      return produce(state, draftState => {
        // const { reportid, error } = payload;
      });

    /**
     * REPORT_ADD_SPLIT_X
     * REPORT_UPDATE_SPLIT_X
     * REPORT_DELETE_SPLIT_X
     */
    case reportsActionTypes.REPORT_ADD_SPLIT_REQUEST:
    case reportsActionTypes.REPORT_UPDATE_SPLIT_REQUEST:
    case reportsActionTypes.REPORT_DELETE_SPLIT_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });

    case reportsActionTypes.REPORT_ADD_SPLIT_SUCCESS:
    case reportsActionTypes.REPORT_UPDATE_SPLIT_SUCCESS:
    case reportsActionTypes.REPORT_DELETE_SPLIT_SUCCESS:
      return produce(state, draftState => {
        const { reportId, report } = payload;
        draftState.list[reportId] = report;
        draftState.loading = false;
      });

    case reportsActionTypes.REPORT_ADD_SPLIT_FAILURE:
    case reportsActionTypes.REPORT_UPDATE_SPLIT_FAILURE:
    case reportsActionTypes.REPORT_DELETE_SPLIT_FAILURE:
      return produce(state, draftState => {
        const { error } = payload;
        draftState.loading = false;
        draftState.error = error;
      });

    // case reportsActionTypes.REPOSITION_SPLIT_FAILURE:
    case reportsActionTypes.REPOSITION_SPLIT_SUCCESS:
      return produce(state, draftState => {
        const { reportId, splits } = payload;
        draftState.list[reportId].splits = splits;
      });

    /**
     * SAVE_FILTER_X / DELETE_FILTER_X
     */
    case reportsActionTypes.SAVE_FILTER_SUCCESS:
      return produce(state, draftState => {
        const { reportid, filters } = payload;
        draftState.list[reportid].filters = filters;
      });

    case reportsActionTypes.REPOSITION_FILTER_SUCCESS:
      return produce(state, draftState => {
        const { reportid, filters } = payload;
        draftState.list[reportid].filters = filters;
      });

    case reportsActionTypes.DELETE_FILTER_SUCCESS:
      return produce(state, draftState => {
        const { reportid, filters } = payload;
        draftState.list[reportid].filters = filters;
      });

    // --- c&a pagecontent list logic start ---
    case reportsActionTypes.LOAD_REPORTPAGE_FOLDERS_SUCCESS:
      const { folders } = payload;
      return produce(state, draftState => {
        draftState.reportFolders = folders;
      });
    // --- c&a pagecontent list logic end ---

    default:
      return state;
  }
};
