import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Collapse, ListGroupItem, ListGroupItemHeading } from 'reactstrap';

import { getChartTypeIcon } from '../helper/getChartTypeIcon';
import { useControlledState } from 'smv-helpers';
import { reportsActions } from 'smv-redux';

import { ReportContentListTree } from './ReportContentListTree';
import { EditFolderButton, EditFolderModal } from '../../project-results/modals';

import './ReportContentList.scss';

export const ReportContentList = ({ pageid, readonly, sharedReportView }) => {
  const params = useParams();
  const { surveyid, reportid } = params;
  let projectid = params.projectid;
  const reduxProjectId = useSelector(state => state.project.id);

  if (!projectid && reduxProjectId) {
    projectid = reduxProjectId;
  }

  // const IS_PROJECT_BY_CA = projectid === '65d602b39a546da12c534749';
  const IS_PROJECT_BY_CA = projectid === '65e1b6c5d4d0e1ddde93c230';

  const reportFolders = useSelector(
    state =>
      state.survey.reports.reportFolders ?? {
        list: {},
        // order: [],
        rootFolderId: ''
      }
  );

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const toggleCollapseOpen = useCallback(() => setIsCollapseOpen(state => !state), []);

  const [EditButton, EditModal] = useControlledState(EditFolderButton, EditFolderModal, false);

  const intl = useIntl();
  const projectSettings = useSelector(state => state?.projectSettings);

  const selectedPageId = projectSettings?.[projectid]?.[surveyid]?.[reportid]?.selectedPageId;

  const page = useSelector(state => state?.survey?.reports?.list?.[reportid]?.pages?.list?.[pageid ?? selectedPageId]);
  const tables = useSelector(state => state?.survey?.tables?.list);

  const pageElements = page?.elements;

  const nodeOnClick = useCallback((e, id) => {
    e.stopPropagation();

    const element = document.querySelector('#' + id);

    if (element) {
      const timeout = 20;

      setTimeout(() => {
        const offset = 200;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, timeout);

      const classes = ['smv-scroll-indicator'];

      element.classList.add(...classes);
      setTimeout(() => element.classList.remove(...classes), timeout + 1200);
    }
  }, []);

  const list = useMemo(() => {
    if (!pageElements)
      return (
        <div className='ml-3 my-1'>
          {intl.formatMessage({ id: 'smoove.page.reports.page-content-list.no-charts-available' })}
        </div>
      );

    const charts = Object.values(pageElements).filter(el => el.sourceType === 'table');

    const _listContent = [];
    Object.values(charts).forEach(chart => {
      _listContent.push({
        chart: chart,
        table: tables[chart.sourceId]
      });
    });
    const _sortByName = (a, b) => {
      const aName = (a.chart?.chartConfig?.title?.length > 0 ? a.chart?.chartConfig?.title : a.table?.name ?? '')
        .toString()
        .toLowerCase();
      const bName = (b.chart?.chartConfig?.title?.length > 0 ? b.chart?.chartConfig?.title : b.table?.name ?? '')
        .toString()
        .toLowerCase();
      return aName.localeCompare(bName);
    };

    return _listContent?.sort(_sortByName)?.map((el, idx) => {
      if (!el?.chart || !el.table) return <div key={`no-element_${idx}`}></div>;
      const chartScrollId = `dashboard-element-${el.chart.id}`;
      return (
        <div className='pointer ml-3 my-2' onClick={e => nodeOnClick(e, chartScrollId)} key={el.chart.id}>
          <span className={`${getChartTypeIcon(el.table.chart.chartType)} mr-1 text-secondary`}></span>
          <span>{el.chart?.chartConfig?.title?.length > 0 ? el.chart?.chartConfig?.title : el.table?.name}</span>
        </div>
      );
    });
  }, [pageElements, tables, nodeOnClick, intl]);

  const handler = useMemo(() => {
    return {
      saveFolder: folder => {
        if (folder.id) {
          reportsActions.updateFolder(reportid, pageid, folder.id, folder).then(r => {
            reportsActions.loadFolders(reportid, pageid);
          });
        } else {
          reportsActions
            .createFolder(surveyid, reportid, pageid, {
              ...folder,
              parentId: folder.parentId ?? reportFolders.rootFolderId
            })
            .then(() => {
              reportsActions.loadFolders(reportid, pageid);
            });
        }
      },
      deleteFolder: async folderid => {
        await reportsActions.deleteFolder(reportid, pageid, folderid);
        await reportsActions.loadFolders(reportid, pageid);
      },
      nodeOnClick: nodeOnClick
    };
  }, [nodeOnClick, pageid, reportFolders.rootFolderId, reportid, surveyid]);

  return (
    <ListGroupItem className={'reports-list report-content-list '}>
      <ListGroupItemHeading className='pointer mb-0 py-2 list-item-sticky'>
        <div className='reports-list__headline'>
          <i onClick={toggleCollapseOpen} className={`far fa-chevron-${isCollapseOpen ? 'down' : 'right'}`}></i>
          <span onClick={toggleCollapseOpen} className='ml-1 reports-list__headline-text'>
            {intl.formatMessage({ id: 'smoove.page.reports.page-content-list.page-content' })}
          </span>
          {IS_PROJECT_BY_CA && !readonly && <EditButton mode={'add'} type={'icon-only'} />}
        </div>
      </ListGroupItemHeading>
      <Collapse isOpen={isCollapseOpen}>
        {/* non-c&a kunden */}
        {!IS_PROJECT_BY_CA && list}
        {/* c&a only */}
        {IS_PROJECT_BY_CA && (
          <ReportContentListTree handler={handler} readonly={readonly} sharedReportView={sharedReportView} />
        )}
      </Collapse>
      <EditModal handler={handler} mode={'add'} />
    </ListGroupItem>
  );
};
