import React, { useMemo } from 'react';
import { Col, FormGroup, Input, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { chartSettings } from '../config';
import { ConditionalFormattingTableButtonAndModal } from './modals';
export const ChartTableFormatConfig = ({ tableConfig, chartConfig, resultTableType, handler }) => {
  const intl = useIntl();

  const chartTypeSettings = useMemo(() => chartSettings?.[resultTableType]?.[chartConfig?.chartType] ?? {}, [
    resultTableType,
    chartConfig?.chartType
  ]);

  if (chartTypeSettings?.conditionalFormattingTable !== true) return null;

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.chart-config.table.format'} defaultMessage={'Format'} />
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.tables.chart-config.table.column-width-auto'}
            defaultMessage={'Auto column width'}
          />
        </Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`tableColumnWidthAuto`}
              name='tableColumnWidthAuto'
              checked={chartConfig?.tableColumnWidthAuto ?? true}
              onChange={handler.setTableColumnWidthAuto}
            />
          </FormGroup>
        </Col>
      </Row>

      {chartConfig?.tableColumnWidthAuto === false && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={'smoove.page.tables.chart-config.table.column-width'}
              defaultMessage={'Column width (in px)'}
            />
          </Col>
          <Col md={7}>
            <Input
              type='number'
              step={5}
              min={40}
              max={300}
              label={intl.formatMessage({ id: `smoove.page.tables.chart-config.table.column-width` })}
              id={`tableColumnWidth`}
              name='tableColumnWidth'
              value={chartConfig?.tableColumnWidth ?? 40}
              placeholder={'auto'}
              onChange={handler.setTableColumnWidth}
              disabled={chartConfig?.tableColumnWidthAuto ?? false}
            ></Input>
          </Col>
        </Row>
      )}

      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          {intl.formatMessage({ id: `smoove.page.tables.chart-config.conditional-formatting-table.title` })}
        </Col>
        <Col md={7}>
          <ConditionalFormattingTableButtonAndModal
            tableConfig={tableConfig}
            chartConfig={chartConfig}
            handler={handler}
          />
        </Col>
      </Row>
    </ListGroupItem>
  );
};
