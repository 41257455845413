import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import logo from 'smv-assets/img/smoove.png';

import './Footer.scss';

import { ChangeLogModal } from './ChangeLogModal';
import { UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import { userActions } from 'smv-redux';

export const Footer = () => {
  const emoji = useRandomEmoji();

  const loggedIn = useSelector(state => state.authentication.loggedIn);

  const user = useSelector(state => state.authentication.user);

  const currentUserId = useSelector(state => state.authentication.user?.id ?? null);

  const [changeLog, setChangeLog] = useState(null);
  const [isChangeLogModalOpen, setIsChangeLogModalOpen] = useState(false);

  const openChangeLogModal = () => {
    if (!changeLog) {
      fetchChangeLog();
    } else {
      setIsChangeLogModalOpen(true);
    }
  };

  const fetchChangeLog = useCallback(() => {
    axios
      .post(`/changelog`, { initialCall: false })
      .then(res => {
        const log = res.data;

        if (log && log.length > 0) {
          setChangeLog(log);
          setIsChangeLogModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setChangeLog(null);
        setIsChangeLogModalOpen(false);
      });
  }, []);

  const toggleChangeLogModal = useCallback(() => {
    setIsChangeLogModalOpen(b => !b);
  }, []);

  // trigger the changelog modal on initial render in case that the user has not seen the current changelog yet
  useEffect(() => {
    axios
      // on initial call the backend checks if the user has seen the current changelog entry and returns the whole changelog if this is the case
      .post(`/changelog`, { initialCall: true })
      .then(res => {
        const log = res.data;

        // if there is a changelog and it is not empty
        if (log && log.length > 0) {
          // first entry is the current changelog
          const _currentChanges = log[0];

          // get the identifier for the current changelog
          const changeLogIdentifier = `${_currentChanges?.version}.${_currentChanges?.date}`;

          // store the changelog in the state
          setChangeLog(log);
          // open changelog modal
          setIsChangeLogModalOpen(true);
          // update the last seen changes for the user
          userActions.updateLastSeenChanges(currentUserId, { lastSeenChanges: changeLogIdentifier });
        }
      })
      .catch(error => {
        setChangeLog(null);
      });
  }, [loggedIn, currentUserId, user?.lastSeenChanges]);

  return (
    <div className='base-footer-container container-fluid'>
      <div className='base-footer-container__logo' draggable={false} id='base-footer-logo' onClick={openChangeLogModal}>
        <img src={logo} height={`24px`} alt='mindline analytics' />
        <small className='small fw-lighter text-muted' style={{ fontSize: '0.7em', paddingLeft: '10px' }}>
          {`v${global.REACT_APP_VERSION}`}
        </small>
      </div>
      {loggedIn && (
        <>
          <UncontrolledTooltip placement='top' target='base-footer-logo'>
            <FormattedMessage id={'smoove.page.changelog.title'} defaultMessage={'What is new?'} />
          </UncontrolledTooltip>
          {isChangeLogModalOpen && (
            <ChangeLogModal
              changeLogId={0}
              changelog={changeLog}
              isOpen={isChangeLogModalOpen}
              toggle={toggleChangeLogModal}
            />
          )}
        </>
      )}

      <div className='base-footer-container__links'>
        <Link to={'/one/imprint/'} draggable={false}>
          <FormattedMessage id={'core.link.label.imprint'} defaultMessage={'Imprint'} />
        </Link>
        <div className='footer-separator'>|</div>
        <Link to={'/one/gdpr'} draggable={false}>
          <FormattedMessage id={'core.link.label.gdpr'} defaultMessage={'Data Protection & Privacy'} />
        </Link>
        {/* <div className='footer-separator'>|</div>
        <Link to={'/one/gtc'} draggable={false}>
          <FormattedMessage id={'core.link.label.gtc'} defaultMessage={'Terms & Conditions'} />
        </Link> */}
        {loggedIn && (
          <>
            <div className='footer-separator'>|</div>
            <Link to={'/one/help'} draggable={false}>
              <FormattedMessage id={'core.link.label.help'} defaultMessage={'Contact'} />
            </Link>
          </>
        )}
      </div>

      <div className='base-footer-container__info'>
        <span>&copy; 2024 mindline analytics</span>
        <small className='base-footer-container__emoji'>Made with {emoji} in Hamburg, Germany.</small>
      </div>
    </div>
  );
};

const useRandomEmoji = () => {
  const [emoji, setEmoji] = useState(null);

  useEffect(() => {
    const getEmoji = () => {
      const emojis = ['❤️', '🦄', '🚀', '🍺', '🏳️‍🌈'];
      return emojis[Math.floor(Math.random() * emojis.length)];
    };

    setEmoji(getEmoji);
  }, []);

  return emoji;
};
