/**
 * Replaces <p> tags with <span> and <br/>
 *
 * @param {string} html Html containing <p> tags
 *
 * @returns {string} Html with <span> tags and <br>
 */
export const replacePTags = function (html) {
  // the order of replacements is important!
  html = html.replace(/<p><br(?:\/)?><\/p>/gi, '<br/><br/>');
  html = html.replace(/<\/p><p>/gi, '<br/>');
  html = html.replace(/<p>/gi, '<span>');
  html = html.replace(/<\/p>/gi, '</span>');

  return html;
};
