import React, { useMemo } from 'react';

import { getRowOrder, useGetSourceElement } from 'smv-helpers';
import { ResultTableChartHeadHeader, ResultTableChartHeadColumnHeader } from '.';

export const ResultTableChartHeadTransposed = ({ table, tableResult, chartConfig, matrixType }) => {
  const getSourceElement = useGetSourceElement();

  const { rows, config } = table;
  const { showBase = true, tableColumnWidth = undefined } = chartConfig;

  const rowHeadSpan = useMemo(() => {
    let rowHeadSpan = 1;
    if (chartConfig.showQuestionTitleColumn) rowHeadSpan += 1;
    return rowHeadSpan;
  }, [chartConfig.showQuestionTitleColumn]);

  const thead = {
    header: [],
    headerSub1: [],
    headerSub2: []
  };

  thead.header.push(
    <th
      id={chartConfig.showQuestionTitleColumn ? 'header__spare-1_question-title' : 'header__spare-1'}
      key={'header__spare-1'}
      colSpan={rowHeadSpan}
      scope='col'
    ></th>
  );
  thead.headerSub1.push(<th id={'header__spare-1'} key={'columnhead__spare-1'} colSpan={rowHeadSpan}></th>);
  thead.headerSub2.push(<th id={'header__spare-2'} key={'columnhead__spare-2'} colSpan={rowHeadSpan}></th>);

  if (showBase) {
    thead.header.push(<th className={'columnhead__base'} key={'base-row'} scope='col'></th>);
    thead.headerSub1.push(
      <th className={'columnhead__base'} key={'columnhead__base'}>
        Base
      </th>
    );
    thead.headerSub2.push(<th className={'columnhead__base'} key={'columnhead__base-spare'}></th>);
  }

  rows.order.forEach(rowId => {
    const row = rows.list[rowId];
    const { rowOrder: headOrder } = getRowOrder(tableResult?.order, row);

    const { element, elementValues, elementScales } = getSourceElement(row);

    const _columns = [];
    const _matrixChoiceHeaders = {};
    headOrder.forEach(orderItem => {
      const isHidden = orderItem?.hidden ?? false;
      const isExcluded = orderItem?.excluded ?? false;

      if (isHidden || isExcluded) return null;

      let value = elementValues.list[orderItem.subelement];
      if (matrixType === 'compact' && row.sourceType === 'question') value = elementScales.list[orderItem.subelement];
      if (matrixType === 'detail' && row.sourceType === 'question') value = elementScales.list[orderItem.subsubelement];

      if (matrixType === 'detail') {
        _matrixChoiceHeaders[orderItem.subelement] = (
          <ResultTableChartHeadColumnHeader
            key={`columnhead__${element.id}_${orderItem.subelement}`}
            column={elementValues.list[orderItem.subelement]}
            config={config}
            colSpan={
              headOrder.filter(o => o.subelement === orderItem.subelement && o?.hidden !== true && o?.excluded !== true)
                .length
            }
            width={tableColumnWidth}
          />
        );
      }

      _columns.push(
        <ResultTableChartHeadColumnHeader
          key={`columnhead__${element.id}_${[orderItem.subelement, orderItem.subsubelement].filter(Boolean).join('_')}`}
          column={value}
          config={config}
          width={tableColumnWidth}
        />
      );
    });

    if (_columns.length > 0) {
      thead.headerSub1.push(..._columns);
      thead.headerSub2.push(...Object.values(_matrixChoiceHeaders));
      thead.header.push(
        <ResultTableChartHeadHeader
          key={`header__${element.id}`}
          element={element}
          colSpan={_columns.length}
          width={tableColumnWidth}
        />
      );
    }
  });

  return (
    <thead className={'smv-result-table__head'}>
      {(chartConfig?.showQuestionTitleColumn ?? true) && <tr className={'head__row'}>{thead.header}</tr>}
      {matrixType === 'detail' &&
        (chartConfig?.showDetailedMatrixChoiceTitleColumn ?? true) &&
        thead.headerSub2.length > 0 && <tr className={'head__row'}>{thead.headerSub2}</tr>}
      <tr className={'head__row'}>{thead.headerSub1}</tr>
    </thead>
  );
};
