export const userConstants = {
  USERS_LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  USERS_LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  USERS_LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  USERS_LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
  USERS_LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
  USERS_LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

  USERS_RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
  USERS_RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
  USERS_RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

  USERS_DETAILS_REQUEST: 'USERS_DETAILS_REQUEST',
  USERS_DETAILS_SUCCESS: 'USERS_DETAILS_SUCCESS',
  USERS_DETAILS_FAILURE: 'USERS_DETAILS_FAILURE',

  EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

  EDIT_USER_LASTSEENCHANGES_REQUEST: 'EDIT_USER_LASTSEENCHANGES_REQUEST',
  EDIT_USER_LASTSEENCHANGES_SUCCESS: 'EDIT_USER_LASTSEENCHANGES_SUCCESS',
  EDIT_USER_LASTSEENCHANGES_FAILURE: 'EDIT_USER_LASTSEENCHANGES_FAILURE',

  REMEMBER_LOGIN_SUCCESS: 'REMEMBER_LOGIN_SUCCESS',
  REMEMBER_LOGIN_REQUEST: 'REMEMBER_LOGIN_REQUEST',
  REMEMBER_LOGIN_FAILURE: 'REMEMBER_LOGIN_FAILURE',

  RECOVER_REQUEST: 'RECOVER_REQUEST',
  RECOVER_SUCCESS: 'RECOVER_SUCCESS',
  RECOVER_FAILURE: 'RECOVER_FAILURE',

  USERS_SESSION_EXPIRED: 'USERS_SESSION_EXPIRED'
};
