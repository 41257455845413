import React from 'react';

import { stripHtml } from 'smv-helpers';

export const ResultTableChartHeadHeader = ({ element, colSpan = 1, width = undefined }) => {
  let label;

  if (element.type === 'question') {
    label = stripHtml(element.title);
  } else if (element.type === 'sysvar' || element.type === 'calcvar') {
    label = element.label;
  } else if (element.type === 'calculation') {
    label = element.title;
  }

  const widthCombined = width && !isNaN(width) ? (colSpan === 1 ? width : width * colSpan) : undefined;

  return (
    <th
      style={widthCombined !== undefined ? { width: `${widthCombined}px` } : {}}
      scope='col'
      colSpan={colSpan}
      className={'head__header'}
    >
      <div className={'head__header_label'}>
        <span className={`head__header_label--label`}>{label}</span>
      </div>
    </th>
  );
};
