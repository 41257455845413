import React, { useMemo, useCallback, useEffect } from 'react';
import { isEqual, isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import produce from 'immer';
import {
  ResponsiveContainer,
  AreaChart as AreaChartReCharts,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  LabelList
} from 'recharts';

import {
  flattenKeys,
  getContrastColor,
  getFixedColor,
  getFormattedValue,
  getSchemeColor,
  useChartData,
  useLegendMouseEffects
} from './helper';

export const AreaChart = ({
  elementid,
  chartConfig,
  table,
  tableResult,
  activeSignificanceType,
  getOpacitySignificance,
  handler
}) => {
  const [chartData, chartDimensions] = useChartData({
    chartConfig,
    table,
    tableResult,
    transpose: chartConfig?.tableTranspose ?? false
  });

  const selectedClientId = useSelector(state => state.clients.selected);
  const colorScales = useSelector(
    state => state.clients?.list?.[selectedClientId]?.colorScales ?? { list: {}, order: [] }
  );

  /**
   * DOMAIN
   */
  const domain = useMemo(() => {
    if (!!chartConfig?.chartDomainMax && isNumber(parseFloat(chartConfig.chartDomainMax))) {
      return [0, parseFloat(chartConfig.chartDomainMax)];
    } else {
      return [0, 'auto'];
    }
  }, [chartConfig?.chartDomainMax]);

  const valueFormatter = useCallback(value => getFormattedValue(value, table.config), [table.config]);

  /**
   * AXIS
   */
  const { xAxis, yAxis } = useMemo(() => {
    let xAxis, yAxis;

    if (!chartConfig?.chartOrientation || chartConfig.chartOrientation === 'horizontal') {
      xAxis = <XAxis dataKey='name' type='category' interval={0} padding={{ left: 25, right: 25 }} />;
      yAxis = <YAxis type='number' domain={domain} padding={{ top: 25 }} />;
    } else if (chartConfig.chartOrientation === 'vertical') {
      xAxis = <XAxis type='number' domain={domain} allowDataOverflow={true} />;
      yAxis = <YAxis dataKey='name' type='category' interval={0} width={130} padding={{ top: 25, bottom: 25 }} />;
    }

    return { xAxis, yAxis };
  }, [chartConfig, domain]);

  /**
   * HOVER EFFECTS
   */
  const flattendHiddenSeries = useMemo(() => {
    if (chartConfig.legendSaveHiddenSeries ?? false) {
      return flattenKeys(chartConfig.legendHiddenSeries ?? {});
    } else {
      return {};
    }
  }, [chartConfig.legendSaveHiddenSeries, chartConfig.legendHiddenSeries]);
  const { onMouseEnter, onMouseLeave, getOpacity, isHidden, toggleSeries, hiddenSeries } = useLegendMouseEffects({
    hiddenSeries: flattendHiddenSeries
  });

  useEffect(() => {
    if (handler?.setTableConfig) {
      handler.setTableConfig(s => {
        // hidden series should be saved, and the current state is different to stored state => update table config
        if (
          s.chart.legendSaveHiddenSeries === true &&
          !isEqual(flattenKeys(s.chart.legendHiddenSeries), hiddenSeries)
        ) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = hiddenSeries;
          });
        }
        // hidden series should not be saved, but the stored state not empty => update table config
        else if (s.chart.legendSaveHiddenSeries !== true && !isEqual(s.chart.legendHiddenSeries, {})) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = {};
          });
        }
        // no update required, series should not be saved or stored state equals current state
        else {
          return s;
        }
      });
    }
  }, [handler, hiddenSeries]);

  if (!chartData) return null;

  const { colorSchema, colorSchemaReverse } = chartConfig;

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <AreaChartReCharts width={200} height={120} data={chartData} layout={chartConfig?.chartOrientation}>
        {(chartConfig?.showGrid ?? false) && <CartesianGrid strokeDasharray='3 3' />}
        {xAxis}
        {yAxis}

        {(chartConfig?.showTooltip ?? true) && (
          <Tooltip formatter={valueFormatter} labelFormatter={label => <strong>{label}</strong>} />
        )}

        {(chartConfig?.showLegend ?? true) && (
          <Legend
            iconType={'plainline'}
            iconSize={chartConfig?.legendIconSize ?? 14}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={toggleSeries}
            formatter={(value, entry) => {
              const val = entry?.payload?.name ?? value;
              return <span style={{ color: '#666' }}>{val}</span>;
            }}
          />
        )}

        {(chartConfig?.dimensionsReversed === true ? [...chartDimensions].reverse() : [...chartDimensions]).map(
          (dimension, index) => {
            // get colors
            let scalePointValue = index / (chartDimensions.length - 1);
            if (isNaN(scalePointValue)) scalePointValue = 0;
            if (colorSchemaReverse) scalePointValue = 1 - scalePointValue;

            let { color, contrastColor } = getSchemeColor({ colorScales, colorSchema, value: scalePointValue });

            const cellIdentifier = dimension.cellIdentifier;
            const fixedColor = getFixedColor(chartConfig, cellIdentifier);
            if (fixedColor) {
              color = fixedColor.hex;
              contrastColor = getContrastColor(fixedColor.rgb);
            }

            const valueSize = 12; // default text font size
            const circleRadius = valueSize * 1.25;

            return (
              <Area
                key={dimension.id}
                name={dimension.label}
                dataKey={`${dimension.id}.value`}
                stroke={color}
                fill={color}
                stackId={chartConfig?.chartSubtype === 'stacked' ? '1' : false}
                opacity={getOpacity(dimension.id)}
                hide={isHidden(`${dimension.id}.value`)}
                isAnimationActive={false}
                dot={
                  chartConfig?.significancesShow && (
                    <CustomizedDot
                      r={circleRadius}
                      fill={color}
                      significancesShow={chartConfig?.significancesShow ?? false}
                      getOpacitySignificance={getOpacitySignificance}
                      activeSignificanceType={activeSignificanceType}
                      chartConfig={chartConfig}
                    />
                  )
                }
                activeDot={false}
              >
                {chartConfig?.valuesShow && (
                  <LabelList
                    dataKey={`${dimension.id}.value`}
                    position={chartConfig?.significancesShow ? 'inside' : 'bottom'}
                    formatter={valueFormatter}
                    fill={contrastColor}
                    opacity={activeSignificanceType ? 0.2 : getOpacity(dimension.id)}
                  />
                )}
              </Area>
            );
          }
        )}
      </AreaChartReCharts>
    </ResponsiveContainer>
  );
};

const CustomizedDot = props => {
  let fill = props.fill;
  const {
    chartConfig,
    cx,
    cy,
    r,
    dataKey,
    payload,
    opacity,
    significancesShow,
    getOpacitySignificance,
    activeSignificanceType
  } = props;

  const stroke = '#fff';
  const strokeWidth = 2;

  const _dataKey = dataKey?.split('.')?.[0];

  const cellIdentifier = props?.payload?.[_dataKey]?.cellIdentifier;

  const fixedColor = getFixedColor(chartConfig, cellIdentifier);
  if (fixedColor) fill = fixedColor.hex;

  const significance = payload?.significances[_dataKey] ?? null;

  const _opacity =
    activeSignificanceType && getOpacitySignificance !== undefined ? getOpacitySignificance(significance) : opacity;

  if (!cx || !cy) return null;

  if (significancesShow && significance) {
    const sigHighColor = '#008000';
    const sigLowColor = '#FF0000';
    const sigMutuallyColor = '#ac4bbd';

    const _stroke =
      significance === 'high'
        ? sigHighColor
        : significance === 'low'
        ? sigLowColor
        : significance === 'both'
        ? sigMutuallyColor
        : '#fff';

    return (
      <>
        <circle cx={cx} cy={cy} r={r} stroke={_stroke} strokeWidth={strokeWidth} fill={'#fff'} opacity={_opacity} />
        <circle cx={cx} cy={cy} r={r - strokeWidth - 1} fill={fill} opacity={_opacity} />
      </>
    );
  } else {
    return <circle cx={cx} cy={cy} r={r} stroke={stroke} strokeWidth={strokeWidth} fill={fill} opacity={_opacity} />;
  }
};
