import { useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';
import { useSelector } from 'react-redux';
import { Store as notify } from 'react-notifications-component';
import produce from 'immer';

import { reportsActions } from 'smv-redux/actions';

import { FilterConfig, FiltersListAvailable, FiltersListSelected } from './';

import './EditFilters.scss';

const defaultNotifyProps = {
  insert: 'bottom',
  container: 'bottom-center',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 3000,
    showIcon: true
  }
};

export const EditFiltersButton = ({ toggle, className = '' }) => {
  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={toggle}>
      <i id={'edit-filters-button'} className={'fal fa-edit icon-smv-blue mr-1'} />
      <UncontrolledTooltip target={'edit-filters-button'} delay={{ hide: 500 }} autohide={false}>
        <FormattedMessage id={'smoove.page.common.filters.edit-filters'} defaultMessage={'Edit Filters'} />
      </UncontrolledTooltip>
    </div>
  );
};

export const EditFiltersModal = ({ isOpen, toggle, report, questionnaire, calculations, systemVariables, handler }) => {
  const intl = useIntl();
  // internal filter order to indicate element order on reposition drag events
  const [filterOrder, setFilterOrder] = useState(report.filters.order ?? []);
  const [filterList, setFilterList] = useState(report.filters.list ?? {});
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    setFilterOrder(report.filters.order ?? []);
    setFilterList(report.filters.list ?? {});
  }, [report.filters]);

  const internalHandler = useMemo(() => {
    return {
      setSelectedFilter: setSelectedFilter,
      updateFilter: filter => {
        reportsActions.updateReportFilter(report.id, filter.id, filter);
      },
      addFilter: (sourceId, targetid, sourceType) => {
        const filter = { sourceType, sourceId };
        reportsActions.addReportFilter(report.id, filter);
      },
      removeFilter: filterid => {
        reportsActions.deleteReportFilter(report.id, filterid);
        handler.setActiveFilter(report.filters.list[filterid]);
        if (selectedFilter && filterid === selectedFilter) {
          setSelectedFilter(null);
        }
      }
    };
  }, [report, selectedFilter, handler]);

  const onDragEndSelectedFiltersDrroppable = useCallback(
    result => {
      const { destination, source } = result;

      // const getTargetId = () => {
      //   if (destination.index < source.index) {
      //     return filterOrder[destination.index];
      //   } else {
      //     const targetIndex = destination.index + 1;
      //     return filterOrder[targetIndex] ?? null;
      //   }
      // };

      if (source.droppableId === 'selected-filters-droppable') {
        if (destination.index === source.index) {
          return;
        }
        const newOrder = arrayMoveImmutable(filterOrder, source.index, destination.index);
        setFilterOrder(newOrder);

        const updatedReport = produce(report, draft => {
          draft.filters.order = newOrder;
          return draft;
        });

        reportsActions.updateReport(report.id, updatedReport).then(res => {
          if (res.status === 'error') {
            setFilterOrder(filterOrder);
            notify.addNotification({
              title: intl.formatMessage({
                id: 'smoove.page.common.filters.reposition-error-title',
                defaultMessage: 'An error occured.'
              }),
              message: intl.formatMessage({
                id: 'smoove.page.common.filters.reposition-error-message',
                defaultMessage: 'The filter could not be moved.'
              }),
              type: 'danger',
              ...defaultNotifyProps
            });
          }
        });
      } else if (['question-droppable', 'sysvar-droppable', 'calcvar-droppable'].includes(source.droppableId)) {
        const sourceType = source.droppableId.split('-')[0];
        internalHandler.addFilter(result.draggableId, filterOrder[destination.index], sourceType);
      }
    },
    [filterOrder, internalHandler, intl, report]
  );

  const onDragEnd = useCallback(
    result => {
      if (result.destination && result.destination.droppableId === 'selected-filters-droppable') {
        onDragEndSelectedFiltersDrroppable(result);
      }
    },
    [onDragEndSelectedFiltersDrroppable]
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xl'} className='edit-filters-modal'>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={'smoove.page.common.filters.label'} defaultMessage={'Filters'} />
      </ModalHeader>
      <ModalBody className='p-4'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Col md={6}>
              <h6>
                <FormattedMessage
                  id={'smoove.page.common.filters.selected.label'}
                  defaultMessage={'Selected Filters'}
                />
              </h6>
              <Droppable droppableId='selected-filters-droppable'>
                {provided => (
                  <div
                    className={`droppable-default droppable-default--filters-selected`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <FiltersListSelected filterOrder={filterOrder} filterList={filterList} handler={internalHandler} />
                    <div className='d-none'>{provided.placeholder}</div>
                  </div>
                )}
              </Droppable>
            </Col>
            <Col md={6}>
              {!selectedFilter && <FiltersListAvailable report={report} handler={internalHandler} />}

              {!!selectedFilter && <FilterConfig handler={internalHandler} filter={filterList[selectedFilter]} />}
            </Col>
          </Row>
        </DragDropContext>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          <FormattedMessage id={'smoove.common.buttons.close'} defaultMessage={'Close'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const EditFiltersButtonAndModal = ({ report, handler }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const questionnaire = useSelector(state => state.survey.questionnaire);
  const calculations = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey.systemVariables);

  return (
    <>
      <EditFiltersButton toggle={toggle} />
      {isOpen && (
        <EditFiltersModal
          toggle={toggle}
          isOpen={isOpen}
          report={report}
          questionnaire={questionnaire}
          calculations={calculations}
          systemVariables={systemVariables}
          handler={handler}
        />
      )}
    </>
  );
};
