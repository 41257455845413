import { useCallback } from 'react';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { DndProvider, Tree } from '@minoru/react-dnd-treeview';
import { useRouteMatch } from 'react-router-dom';

import React, { useEffect } from 'react';
import { useDragOver } from '@minoru/react-dnd-treeview';
import { DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import { arrayMoveImmutable } from 'array-move';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { reportsActions } from 'smv-redux';

import { SmvCustomDropdown } from 'src/smoove/components/misc';
import { useControlledState } from 'smv-helpers';
import { getChartTypeIcon } from '../../project-reports/helper/getChartTypeIcon';
import {
  EditFolderButton,
  EditFolderModal,
  DeleteFolderButton,
  DeleteFolderModal
} from 'src/smoove/components/project-results/modals';

import { CustomDragPreview, Placeholder } from '../../project-results/TableTree';

export const ReportContentListTree = ({ handler, readonly = false, sharedReportView }) => {
  const surveyId = useSelector(state => state.survey.id);
  const tables = useSelector(state => state.survey.tables);
  const reportFolders = useSelector(
    state =>
      state.survey.reports.reportFolders ?? {
        list: {},
        // order: [],
        rootFolderId: ''
      }
  );

  const { params } = useRouteMatch();
  const { reportid, pageid, token } = params;
  const pageElements = useSelector(
    state => state.survey.reports?.list?.[reportid]?.pages?.list?.[pageid]?.elements ?? null
  );

  useEffect(() => {
    if (surveyId && reportid && pageid) {
      if (token) {
        reportsActions.loadFoldersSharedReport(reportid, pageid, token).then(res => {
          // console.log(res);
        });
      } else {
        reportsActions.loadFolders(reportid, pageid).then(res => {
          // console.log(res);
        });
      }
    }
  }, [surveyId, reportid, pageid, token]);

  const treeData = getTreeData(reportFolders, tables, pageElements);

  const handleDrop = useCallback(
    (newTree, { dragSourceId, dropTargetId, dragSource, dropTarget, destinationIndex, relativeIndex }) => {
      if (dragSource.type === 'folder') {
        reportsActions
          .updateFolder(surveyId, reportid, pageid, dragSourceId, { parentId: dropTargetId, position: relativeIndex })
          .then(() => {
            reportsActions.loadFolders(reportid, pageid);
          });
      } else if (dragSource.type === 'dashboardElement') {
        const oldParentFolder = reportFolders.list[dragSource.parent];
        const newParentFolder = reportFolders.list[dropTargetId];

        if (dragSource.parent === dropTargetId) {
          const oldIndexOfElement = oldParentFolder.children.findIndex(el => el.id === dragSourceId);
          // check if moving down to address issue of moving element on step too far
          const isMovingDown = oldIndexOfElement < relativeIndex;
          // moved in the same folder, just update one folder
          const _folder = produce(oldParentFolder, draftState => {
            draftState.children = arrayMoveImmutable(
              oldParentFolder.children,
              oldIndexOfElement,
              isMovingDown ? relativeIndex - 1 : relativeIndex
            );
          });
          reportsActions.updateFolder(reportid, pageid, dragSource.parent, _folder).then(r => {
            reportsActions.loadFolders(reportid, pageid);
          });
        } else {
          // remove element from old parent folders children
          const _oldfolder = produce(oldParentFolder, draftState => {
            draftState.children = oldParentFolder.children.filter(el => el.id !== dragSourceId);
          });
          // update old folder
          reportsActions.updateFolder(reportid, pageid, dragSource.parent, _oldfolder).then(r => {
            // update new folder by inserting element at correct position in children array
            const _newfolder = produce(newParentFolder, draftState => {
              draftState.children = newParentFolder.children.toSpliced(relativeIndex, 0, {
                id: dragSourceId,
                type: 'dashboardElement'
              });
            });
            // update new folder
            reportsActions.updateFolder(reportid, pageid, dropTargetId, _newfolder).then(r2 => {
              reportsActions.loadFolders(reportid, pageid);
            });
          });
        }
      }
    },
    [pageid, reportid, surveyId, reportFolders.list]
  );

  if (treeData.length === 0) return null;

  if (sharedReportView) {
    // add a DndProvider if list is rendered in shared report, even if we cant dnd because its readonly. Tree would not work otherwise...
    return (
      <DndProvider backend={HTML5Backend}>
        <Tree
          tree={treeData}
          // initialOpen={true}
          rootId={reportFolders.rootFolderId}
          render={(node, { isOpen, onToggle, isDropTarget, isDragSource, containerRef }) => {
            return (
              <ReportContentListTreeNode
                node={node}
                isOpen={isOpen}
                onToggle={onToggle}
                isDropTarget={isDropTarget}
                isDragSource={isDragSource}
                handler={handler}
                readonly={readonly}
                tables={tables}
                folders={reportFolders}
                containerRef={containerRef}
                pageElements={pageElements}
              />
            );
          }}
          dragPreviewRender={monitorProps => <CustomDragPreview monitorProps={monitorProps} />}
          onDrop={handleDrop}
          classes={{
            container: 'table-tree',
            draggingSource: 'table-tree--dragging-source',
            placeholder: 'table-tree--placeholder-container',
            dropTarget: 'table-tree--drop-target'
          }}
          sort={false}
          insertDroppableFirst={false}
          canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
            if (readonly) return false;

            if (dragSource?.parent === dropTargetId) {
              return true;
            }
          }}
          canDrag={() => {
            if (readonly) return false;
            return true;
          }}
          dropTargetOffset={5}
          placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
        />
      </DndProvider>
    );
  }

  return (
    <Tree
      tree={treeData}
      // initialOpen={true}
      rootId={reportFolders.rootFolderId}
      render={(node, { isOpen, onToggle, isDropTarget, isDragSource, containerRef }) => {
        return (
          <ReportContentListTreeNode
            node={node}
            isOpen={isOpen}
            onToggle={onToggle}
            isDropTarget={isDropTarget}
            isDragSource={isDragSource}
            handler={handler}
            readonly={readonly}
            tables={tables}
            folders={reportFolders}
            containerRef={containerRef}
            pageElements={pageElements}
          />
        );
      }}
      dragPreviewRender={monitorProps => <CustomDragPreview monitorProps={monitorProps} />}
      onDrop={handleDrop}
      classes={{
        container: 'table-tree',
        draggingSource: 'table-tree--dragging-source',
        placeholder: 'table-tree--placeholder-container',
        dropTarget: 'table-tree--drop-target'
      }}
      sort={false}
      insertDroppableFirst={false}
      canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
        if (readonly) return false;

        if (dragSource?.parent === dropTargetId) {
          return true;
        }
      }}
      canDrag={() => {
        if (readonly) return false;
        return true;
      }}
      dropTargetOffset={5}
      placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
    />
  );
};

export const ReportContentListTreeNode = ({
  node,
  handler,
  isOpen,
  onToggle,
  isDropTarget,
  readonly,
  tables,
  folders,
  containerRef,
  pageElements
}) => {
  const isFolder = node?.type === 'folder';

  const dragOverProps = useDragOver(node.id, isOpen, onToggle);

  const handleToggle = e => {
    e.stopPropagation();
    onToggle(node.id);
  };

  return (
    <div
      className={classnames(`custom-node`, {
        'custom-node--is-drop-target': isDropTarget,
        'custom-node--is-open': isOpen,
        'custom-node--readonly': readonly
      })}
      {...dragOverProps}
    >
      {node.droppable && <i onClick={handleToggle} className={`far fa-chevron-${isOpen ? 'down' : 'right'}`} />}
      {isFolder ? (
        <Folder
          folder={folders.list[node.id]}
          handler={handler}
          readonly={readonly}
          toggle={handleToggle}
          isOpen={isOpen}
          node={node}
        />
      ) : (
        <Chart
          chart={pageElements[node.id]}
          table={tables.list[pageElements[node.id].sourceId]}
          handler={handler}
          readonly={readonly}
          containerRef={containerRef}
          node={node}
        />
      )}
    </div>
  );
};

const Folder = ({ folder, handler, readonly, toggle, isOpen, node }) => {
  const [EditButton, EditModal] = useControlledState(EditFolderButton, EditFolderModal, false);
  const [AddButton, AddModal] = useControlledState(EditFolderButton, EditFolderModal, false);
  const [DeleteButton, DeleteModal] = useControlledState(DeleteFolderButton, DeleteFolderModal, false);

  if (!folder) return null;

  const hasChildren = folder.children?.length > 0;

  return (
    <div className='table-folder'>
      <i className={`far fa-folder${isOpen ? '-open' : ''} px-1`} onClick={toggle} />
      <div className='table-folder__label' onClick={toggle} title={folder.name}>
        {folder.name}
      </div>
      {!readonly && (
        <div className='ml-auto'>
          <SmvCustomDropdown>
            <DropdownItem>
              <AddButton mode={'add'} />
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <EditButton mode={'edit'} />
            </DropdownItem>
            {!hasChildren && (
              <>
                <DropdownItem divider />
                <DropdownItem>
                  <DeleteButton />
                </DropdownItem>
              </>
            )}
          </SmvCustomDropdown>
          <AddModal mode={'add'} folder={folder} handler={handler} parentId={node.id} />
          <EditModal mode={'edit'} folder={folder} handler={handler} parentId={node.parent} />
          {!hasChildren && (
            <DeleteModal deleteHandler={() => handler.deleteFolder(folder.id)} title={`'${folder?.name}'`} />
          )}
        </div>
      )}
    </div>
  );
};

const Chart = ({ chart, table, handler, readonly, containerRef, node }) => {
  useEffect(() => {
    // neede only for reports page
    if (readonly && containerRef) {
      const handler = e => {
        e.dataTransfer.setData('text/plain', '');
        e.dataTransfer.setData('sourceType', 'table');
        e.dataTransfer.setData('sourceId', chart.id);
      };
      containerRef.current.addEventListener('dragstart', handler);
    }
  }, [chart, readonly, containerRef]);

  if (!chart || !table) return <div key={`no-element_${chart?.id}`}></div>;

  const chartScrollId = `dashboard-element-${chart.id}`;

  return (
    <div className='pointer my-2' onClick={e => handler.nodeOnClick(e, chartScrollId)} key={chart.id}>
      <span className={`${getChartTypeIcon(table.chart.chartType)} mr-1 text-secondary`}></span>
      <span>{chart?.chartConfig?.title?.length > 0 ? chart?.chartConfig?.title : table?.name}</span>
    </div>
  );
};

function getTreeData(folders, tables, pageElements) {
  const tree = [];
  const rootFolder = folders?.list?.[folders?.rootFolderId];

  if (!rootFolder || !tables) {
    return [];
  }

  tree.push({
    id: rootFolder.id,
    parent: null,
    text: rootFolder.name,
    type: 'folder',
    droppable: true
  });

  function traverseFolder(folder) {
    // console.log("traversing folder " + folder.name);

    folder.children.forEach(child => {
      // console.log(child);
      if (child.type === 'folder') {
        const currentFolder = folders.list[child.id];
        if (currentFolder) {
          // console.log('adding child folder ' + currentFolder.name);

          tree.push({
            id: child.id,
            parent: folder.id,
            text: currentFolder.name ?? 'no name',
            type: 'folder',
            droppable: true
          });

          traverseFolder(currentFolder);
        }
      } else if (child.type === 'dashboardElement') {
        const dashboardElement = pageElements?.[child?.id];
        if (!dashboardElement) return;
        const table = tables.list[dashboardElement.sourceId];
        if (table) {
          // console.log("adding element " + (dashboardElement?.chartConfig?.title ?? table.name ?? 'no name'));

          tree.push({
            id: child.id,
            parent: folder.id,
            text: dashboardElement?.chartConfig?.title ?? table.name ?? 'no name',
            type: 'dashboardElement',
            droppable: false
          });
        }
      }
    });
  }

  traverseFolder(rootFolder);
  return tree;
}
