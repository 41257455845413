import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Input, Row } from 'reactstrap';

export const ColorScale = ({ colorSchema = 'viridis', onChange, lg = 7, xl = 7 }) => {
  const selectedClientId = useSelector(state => state.clients.selected);
  const colorScales = useSelector(
    state => state.clients?.list?.[selectedClientId]?.colorScales ?? { list: {}, order: [] }
  );

  const colorScaleOptions = useMemo(
    () =>
      colorScales?.order?.map((scaleid, i) => {
        let scaleName = colorScales.list[scaleid]?.name;
        if (!scaleName || scaleName === '') scaleName = `Scale ${i + 1}`;
        return (
          <option key={scaleid} value={scaleid}>
            {scaleName}
          </option>
        );
      }),
    [colorScales]
  );

  return (
    <Row className='mt-1 align-items-center colorscale-selector'>
      <Col md={5} lg={12 - lg} xl={12 - xl}>
        <FormattedMessage id={'smoove.page.tables.chart-config.color-schema'} defaultMessage={'Color Scheme'} />
      </Col>
      <Col md={7} lg={lg} xl={xl}>
        <Input
          type='select'
          label={'Show Value Labels'}
          id={`colorSchema`}
          name='colorSchema'
          value={colorSchema}
          onChange={onChange}
        >
          {colorScaleOptions}
          <option value={'rdylgr'}>Red-Yellow-Green</option>
          <option value={'rdylbu'}>Red-Yellow-Blue</option>
          <option value={'viridis'}>Viridis (default)</option>
          <option value={'blues'}>Blues</option>
          <option value={'spectral'}>Spectral</option>
          <option value={'rainbow'}>Rainbow</option>
          <option value={'sinebow'}>Sinebow</option>
          <option value={'turbo'}>Turbo</option>
          <option value={'plasma'}>Plasma</option>
        </Input>
      </Col>
    </Row>
  );
};
