import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, UncontrolledTooltip } from 'reactstrap';

export const CalculationVarnameInput = ({ calculation, readOnly, calculationHandler }) => {
  const intl = useIntl();
  const [varnameInput, setVarnameInput] = useState(calculation.varname || '');
  const [isVarnameValid, setisVarnameValid] = useState(true);

  const handleSetVarnameInput = value => {
    setVarnameInput(value);
    const isValidVarname = /^[a-zA-Z]{1}[\w@#.$]{0,52}[a-zA-Z0-9@#$]{1}$/.test(value);
    if (!isValidVarname) {
      setisVarnameValid(false);
      return;
    } else {
      setisVarnameValid(true);
    }
  };

  const saveVarname = value => {
    if (isVarnameValid) {
      calculationHandler.updateCalculation(calculation.id, { varname: value });
    } else {
      setVarnameInput(calculation.varname);
      setisVarnameValid(true);
    }
  };

  return (
    <div>
      <Input
        type='text'
        value={varnameInput || ''}
        placeholder={intl.formatMessage({ id: `smoove.page.data.calculation.enter-variable-name` })}
        onChange={e => handleSetVarnameInput(e.target.value)}
        disabled={readOnly}
        id={`varname_${calculation.id}_container`}
        onBlur={() => saveVarname(varnameInput)}
      />
      <UncontrolledTooltip
        placement='top'
        target={`varname_${calculation.id}_container`}
        fade={true}
        style={{ textAlign: 'left' }}
      >
        {intl.formatMessage({ id: 'smoove.page.data.calculation.varname.varname-naming-rules' })}
        <ul>
          <li>
            {intl.formatMessage({
              id: 'smoove.page.data.calculation.varname.must-start-with-exactly-one-letter-uppercase-or-lowercase'
            })}
          </li>
          <li>
            {intl.formatMessage({
              id:
                'smoove.page.data.calculation.varname.can-then-contain-0-to-52-characters-that-are-alphanumeric-underscore-or'
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: 'smoove.page.data.calculation.varname.must-end-with-exactly-one-character-that-is-a-letter-digit-or'
            })}
          </li>
          <li>{intl.formatMessage({ id: 'smoove.page.data.calculation.varname.must-not-contain-a-hyphen' })}</li>
        </ul>
      </UncontrolledTooltip>
      {!isVarnameValid && (
        <small className='text-warning'>
          {intl.formatMessage({ id: 'smoove.page.data.calculation.varname.invalid-varname' })}
        </small>
      )}
    </div>
  );
};
