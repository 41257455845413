import React, { useState, useEffect, useCallback } from 'react';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';
import { questionnaireActions } from 'smv-redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import { SharedChoicesConfig, ConfigChoiceItem } from '.';
import { NewItem } from '../../../../question/components';

import './ConfigChoices.scss';

export const ConfigChoices = ({ surveyid, question, expertMode, writeable }) => {
  const [order, setOrder] = useState([]);

  const intl = useIntl();

  const [paginationLimit, setPaginationLimit] = useState(10);

  const toggleRandomizeChoices = useCallback(async () => {
    const order = question.config?.choices?.order;
    if (!order || order.length === 0) {
      return;
    }

    const areRandomized = !Object.values(question?.config?.choices?.list).some(choice => {
      return choice?.config?.randomize === false;
    });

    await questionnaireActions.updateAllChoices(surveyid, question.id, {
      randomize: !areRandomized
    });
  }, [surveyid, question?.id, question?.config?.choices?.order, question?.config?.choices?.list]);

  useEffect(() => {
    if (question?.config?.choices?.order) {
      setOrder(question?.config?.choices?.order);
    }
  }, [question]);

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const { source, destination } = result;
      const newOrder = arrayMoveImmutable(order, source.index, destination.index);
      setOrder(newOrder);

      questionnaireActions.updateChoice(surveyid, question.id, order[source.index], { position: destination.index });

      // surveyActions.moveSubElement(surveyid, question.id, order[source.index], 'choice', targetid).then(res => {
      //   if (res.status === 'error') {
      //     notify.addNotification({
      //       title: intl.formatMessage({
      //         id: 'smoove.questionnaire.modal.config-tab.move-sub-element-error-title',
      //         defaultMessage: 'An error occured.'
      //       }),
      //       message: intl.formatMessage({
      //         id: 'smoove.questionnaire.modal.config-tab.move-sub-element-error-message',
      //         defaultMessage: 'The move operation was unsuccessful.'
      //       }),
      //       type: 'danger',
      //       ...defaultNotifyProps
      //     });
      //     // incase of error revert to previous order
      //     setOrder(order);
      //   }
      // });
    },
    [order, surveyid, question.id]
  );

  return (
    <>
      <div className='config-choices__headline'>
        <FormattedMessage
          id={`smoove.questionnaire.modal.config-tab.choices.imported-choices`}
          defaultMessage={'Imported choices (items)'}
        />
      </div>
      <SharedChoicesConfig question={question} writeable={writeable} />

      <hr />

      <div className='config-choices__headline'>
        <FormattedMessage
          id={`smoove.questionnaire.modal.config-tab.choices.question-choices`}
          defaultMessage={'Question choices (items)'}
        />
      </div>
      <div className='config-choices__head'>
        <div className='d-flex justify-content-center'>
          <i className='far fa-sort-alt text-center' id={'sort-dnd'}></i>
        </div>
        <div className='config-choices__head-name'>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.choices.name`} defaultMessage={'Name'} />
        </div>
        {/* only in expert mode: value */}
        {expertMode && order.length > 0 && (
          <div className={`config-choices__head-value`}>
            <FormattedMessage id={`smoove.questionnaire.modal.config-tab.choices.value`} defaultMessage={'Value'} />
          </div>
        )}

        {/* randomize btn */}
        {writeable && order.length > 1 && (
          <>
            <i className={`far fa-random pointer text-center`} id='randomize-all' onClick={toggleRandomizeChoices} />
            <UncontrolledTooltip placement='top' target={`randomize-all`}>
              {intl.formatMessage({
                id: `smoove.questionnaire.modal.config-tab.choices.randomize-all`,
                defaultMessage: 'Toggle Randomization'
              })}
            </UncontrolledTooltip>
          </>
        )}

        {/* space for open ended */}
        {writeable && ['multiple_vector', 'single_vector', 'single_matrix'].includes(question.config.qtype) && <i />}

        {/* space for exclusive (in multi vector only) */}
        {writeable && question.config.qtype === 'multiple_vector' && <i />}

        {/* space for hide condition */}
        {writeable && <i />}

        {/* space for delete */}
        {writeable && <i />}
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <MakeDroppable droppableId='choice-item-droppable' writeable={writeable}>
          {order.slice(0, paginationLimit).map((id, index) => {
            const choice = question.config.choices?.list?.[id];
            if (!choice) {
              return null;
            }

            return (
              <MakeDraggable key={id} draggableId={id} index={index} isDragDisabled={!writeable}>
                <ConfigChoiceItem
                  index={index}
                  question={question}
                  choice={choice}
                  qtype={question.config.qtype}
                  expertMode={expertMode}
                  writeable={writeable}
                />
              </MakeDraggable>
            );
          })}
          {order.length > paginationLimit && (
            <small className='text-smv-blue my-1 ml-2 pointer' onClick={() => setPaginationLimit(b => b + 50)}>
              <i className='fal mr-2 fa-angle-double-down' />
              <span>
                ...show {order.length - paginationLimit > 50 ? '50' : (order.length - paginationLimit).toString()} more
                items (total: {order.length.toString()})
              </span>
            </small>
          )}
        </MakeDroppable>
      </DragDropContext>
      {writeable && <NewItem question={question} type={'choice'} renderInConfigModal={true} />}
    </>
  );
};

const getDraggableStyles = (isDragging, draggableStyle) => {
  return {
    // boxSizing: 'border-box',
    // borderLeft: "2px solid transparent",
    // borderRight: "2px solid transparent",
    // borderTop: "3px solid transparent",
    // borderBottom: "3px solid transparent",
    // margin: "8px",
    // borderRadius: isDragging && '4px',
    // background: '#ebebeb',
    // boxShadow:
    //   isDragging && '0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)'
    // ...draggableStyle
  };
};

const MakeDraggable = ({ children, draggableId, index, isDragDisabled }) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            ...getDraggableStyles(snapshot.isDragging)
          }}
          className='draggable-default'
          tabIndex='-1'
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

const getListStyle = isDraggingOver => ({
  // background: '#ebebeb',
  // padding: "4px",
  // // paddingBottom: "4px",
  // borderRadius: "4px"
});

const MakeDroppable = ({ children, droppableId, writeable }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={!writeable}>
      {(provided, snapshot) => {
        return (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className={`droppable-default ${
              writeable ? 'config-choices-droppable' : 'config-choices-droppable--disabled'
            }`}
          >
            {children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};
