import React from 'react';

import { ResultTableOpenEndedListing } from './table/table-listings/ResultTableOpenEndedListing';

export const TableChartListing = ({ chartConfig, table, tableResult, isLoading, handler, limit, setLimit }) => {
  return (
    <ResultTableOpenEndedListing
      table={table}
      tableResult={tableResult}
      handler={handler}
      limit={limit}
      setLimit={setLimit}
      isLoading={isLoading}
    />
  );
};
