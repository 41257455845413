import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Modal, ModalHeader, ModalBody, Row, Col, Form } from 'reactstrap';
import { isArray, isEmpty, isEqual } from 'lodash';
import classNames from 'classnames';
import produce from 'immer';

import {
  getQuestionnaireElementsOrder,
  getSubelementPropertyTranslation,
  isLooped,
  useTranslationHooks
} from 'smv-helpers';
import { reportsActions } from 'smv-redux';
import { MultipleSelectDropdown, TextInputRow } from 'smv-components';

export const EditReportButton = props => {
  return (
    <div className='smv-custom-dropdown__buttons' onClick={props.toggle}>
      <i
        className={classNames('fal fa-edit', {
          'icon-smv-blue mr-1': true
        })}
      ></i>
      <span>
        <FormattedMessage
          id={`smoove.page.reports.modals.edit-report.button-open-edit`}
          defaultMessage={'Edit report'}
        />
      </span>
    </div>
  );
};

export const EditReportModal = ({ isOpen, toggle, report }) => {
  const initial_report = report;
  const [internalReport, setInternalReport] = useState(initial_report);

  const intl = useIntl();

  const canSave = useMemo(() => {
    if (isEmpty(internalReport.name)) return false;
    else if (internalReport.name.length < 3) return false;
    else if (isEqual(internalReport.name, report?.name) && isEqual(internalReport?.config ?? {}, report?.config ?? {}))
      return false;
    else return true;
  }, [internalReport, report]);

  const updateName = useCallback(({ target }) => {
    const { value } = target;
    setInternalReport(state => ({ ...state, name: value }));
  }, []);

  const _updateHandler = useCallback(() => {
    if (internalReport.name !== report.name) {
      if (!canSave) return false;
      reportsActions.updateReport(report.id, { name: internalReport.name });
    }
  }, [internalReport, canSave, report]);

  const filterHandler = useMemo(
    () => ({
      save: filter => {
        if (!filter.sourceId || filter?.sourceSubElements?.length <= 0) return;

        if (!filter.id) {
          reportsActions.addReportStaticFilter(report.id, { ...filter, id: undefined }).then(({ report, filterId }) => {
            setInternalReport(state =>
              produce(state, draft => {
                if (!draft?.config) draft.config = {};
                if (!draft?.config?.staticFilters || isArray(draft.config.staticFilters.list)) {
                  draft.config.staticFilters = { list: {}, order: [] };
                }

                draft.config.staticFilters.order = report.config.staticFilters.order;
                draft.config.staticFilters.list = report.config.staticFilters.list;
              })
            );
          });
        } else {
          reportsActions.updateReportStaticFilter(report.id, filter.id, filter).then(report => {
            setInternalReport(state =>
              produce(state, draft => {
                draft.config.staticFilters.order = report.config.staticFilters.order;
                draft.config.staticFilters.list = report.config.staticFilters.list;
              })
            );
          });
        }
      },
      remove: filterid => {
        reportsActions.deleteReportStaticFilter(report.id, filterid).then(report => {
          setInternalReport(state =>
            produce(state, draft => {
              delete draft.config.staticFilters.list[filterid];
              draft.config.staticFilters.order = draft.config.staticFilters.order.filter(
                _filterid => filterid !== _filterid
              );
            })
          );
        });
      }
    }),
    [report.id]
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} autoFocus={false} size={'lg'}>
      {/* <Form onSubmit={_saveOnSubmit}> */}
      <Form>
        <ModalHeader toggle={toggle}>
          <FormattedMessage
            id={`smoove.page.reports.modals.edit-report.headline-edit`}
            defaultMessage={'Edit report'}
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormattedMessage
                id={`smoove.page.reports.modals.edit-report.form.name.instruction`}
                defaultMessage={'Enter report name'}
              />
              :
            </Col>
          </Row>
          <TextInputRow
            name={'name'}
            title={intl.formatMessage({
              id: `smoove.page.reports.modals.edit-report.form.name.title`,
              defaultMessage: 'Name'
            })}
            // tooltip={intl.formatMessage({
            //   id: `smoove.page.reports.modals.edit-report.form.name.tooltip`,
            //   defaultMessage: ''
            // })}
            value={internalReport.name}
            change={updateName}
            placeholder={intl.formatMessage({
              id: 'smoove.page.reports.modals.edit-report.form.name.placeholder',
              defaultMessage: 'enter name...'
            })}
            autoFocus={true}
            onBlur={_updateHandler}
          />
          <StaticReportFilters handler={filterHandler} report={internalReport} />
        </ModalBody>
        {/* <ModalFooter>
          <Button color='primary' onClick={_updateHandler} disabled={!canSave}>
            {intl.formatMessage({ id: `smoove.common.buttons.save` })}
          </Button>
        </ModalFooter> */}
      </Form>
    </Modal>
  );
};

const StaticReportFilters = ({ handler, report }) => {
  return (
    <>
      <Row>
        <Col>
          <h6 className={'text-smv text-uppercase fw-bold'}>
            <FormattedMessage
              id={`smoove.page.reports.modals.edit-report.form.static-filters.headline`}
              defaultMessage={'Static Report Filters'}
            />
          </h6>
        </Col>
      </Row>
      <Row>
        <Col>
          {(report?.config?.staticFilters?.order ?? []).map(filterid => (
            <StaticReportFilter key={filterid} handler={handler} filter={report.config.staticFilters.list[filterid]} />
          ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <StaticReportFilter handler={handler} />
        </Col>
      </Row>
    </>
  );
};

const DEFAULT_FILTER = { id: null, sourceType: '', sourceId: null, sourceSubElements: {} };

const StaticReportFilter = ({ handler, filter = null }) => {
  const [internalFilter, setInternalFilter] = useState(filter ?? DEFAULT_FILTER);

  const questionnaire = useSelector(state => state.survey.questionnaire);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  // @todo: add support for calculation variables
  // const calculationVariables = useSelector(state => state.survey?.data?.calculations);

  const translationsProps = useTranslationHooks();

  useEffect(() => {
    if (!!filter) {
      setInternalFilter(state => {
        if (isEqual(state, filter)) return state;
        else return filter;
      });
    }
  }, [filter]);

  const removeFilter = useCallback(() => {
    handler.remove(filter.id);
  }, [handler, filter]);

  const changeElement = e => {
    const sourceId = e.target.value;
    const sourceType = e.target.options[e.target.selectedIndex].getAttribute('data-sourcetype');

    setInternalFilter(state =>
      produce(state, draft => {
        if (draft?.sourceId !== sourceId) {
          draft.sourceId = sourceId;
          draft.sourceType = sourceType;
          draft.sourceSubElements = {};
        }
      })
    );
  };

  const changeValues = values => {
    setInternalFilter(state => {
      if (isEqual(values, Object.keys(state.sourceSubElements))) return state;
      return produce(state, draft => {
        draft.sourceSubElements = {};
        values.forEach(valueId => {
          draft.sourceSubElements[valueId] = true;
        });
      });
    });
  };

  useEffect(() => {
    if (
      !!internalFilter?.sourceId &&
      Object.keys(internalFilter?.sourceSubElements ?? {}).length > 0 &&
      !isEqual(filter, internalFilter)
    ) {
      handler.save(internalFilter);
      if (!internalFilter.id) setInternalFilter(DEFAULT_FILTER);
    }
  }, [handler, filter, internalFilter]);

  const _values = useMemo(() => {
    return Object.keys(internalFilter?.sourceSubElements ?? {});
  }, [internalFilter?.sourceSubElements]);

  const questionnaireOrder = getQuestionnaireElementsOrder(questionnaire);
  const questionnaireSources = useMemo(() => {
    return questionnaireOrder
      .map(elementid => {
        const element = questionnaire.list[elementid];
        if (element.type !== 'question' || !['single_vector'].includes(element.config.qtype)) {
          return null;
        }

        if ((element.config?.choices?.order?.length ?? 0) <= 0) {
          return null;
        }

        const { isLoopedQuestion } = isLooped(questionnaire.list, element);
        if (isLoopedQuestion) return null;

        return (
          <option key={elementid} value={elementid} data-sourcetype='question'>
            {`${element.config.varname} - ${element.title}`}
          </option>
        );
      })
      .filter(Boolean);
  }, [questionnaire, questionnaireOrder]);

  const _options = useMemo(() => {
    if (!internalFilter?.sourceId) return [];

    if (internalFilter.sourceType === 'question') {
      return (questionnaire.list?.[internalFilter.sourceId]?.config?.choices?.order ?? []).map(choiceid => {
        const choice = questionnaire.list[internalFilter.sourceId].config.choices.list[choiceid];
        const label = getSubelementPropertyTranslation(
          choice,
          {},
          { useShortcodes: true, useStripped: true },
          translationsProps
        );

        return {
          id: choice.id,
          label: label,
          value: choice.value
        };
      });
    } else if (internalFilter.sourceType === 'sysvar') {
      const systemVariable = systemVariables.list?.[internalFilter.sourceId];
      return systemVariable.order.map(valueId => ({
        id: valueId,
        label: systemVariable.list[valueId].label,
        value: systemVariable.list[valueId].value
      }));
    } else if (internalFilter.sourceType === 'calcvar') {
      // @Todo: add calcvars to report filters
    } else {
      return [];
    }
  }, [questionnaire, systemVariables, internalFilter, translationsProps]);

  return (
    <Row className='mb-2'>
      <Col md={6}>
        <Input
          type='select'
          label={'globalFilterSelect'}
          id={`globalFilterSelect`}
          name='globalFilterSelect'
          value={internalFilter.sourceId ?? ''}
          onChange={changeElement}
        >
          <option value={''}>Select Filter Element</option>
          <optgroup label='Internal Variables'>
            {systemVariables.order.map(systemVariableId => (
              <option key={systemVariableId} value={systemVariableId} data-sourcetype='sysvar'>
                {systemVariables.list[systemVariableId].label}
              </option>
            ))}
          </optgroup>
          <optgroup label='Questions'>{questionnaireSources}</optgroup>
          {/* <optgroup label='Calculated Variables'>
            {calculatedVariableSources}
          </optgroup> */}
        </Input>
      </Col>
      <Col md={5}>
        {_options.length > 0 && <MultipleSelectDropdown options={_options} values={_values} onChange={changeValues} />}
      </Col>
      <Col md={1} className='d-flex align-items-center justify-content-start'>
        {!!internalFilter.id && (
          <div className={`smv-custom-dropdown__buttons`} onClick={removeFilter}>
            <i className='fal fa-trash-alt icon-smv-blue mr-1' />
          </div>
        )}
      </Col>
    </Row>
  );
};
