import { useEffect, useState } from 'react';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import produce from 'immer';

import { reportsActions } from 'smv-redux/actions';
import { DynamicSplitsList, DynamicSplitConfig } from '.';

// import './EditFilters.scss';

const INITAL_SPLIT_STATE = {
  id: null,
  label: null,
  // config: {},
  elements: {
    list: {},
    order: []
  }
};

export const EditDynamicSplitsButton = ({ toggle, className = '' }) => {
  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={toggle}>
      <i id={'edit-dynamic-splits-button'} className={'fal fa-edit icon-smv-blue mr-1'} />
      <UncontrolledTooltip target={'edit-dynamic-splits-button'} delay={{ hide: 500 }} autohide={false}>
        <FormattedMessage id={'smoove.page.common.dynamic-splits.edit-splitting'} defaultMessage={'Edit Splitting'} />
      </UncontrolledTooltip>
    </div>
  );
};

export const EditDynamicSplitsModal = ({ isOpen, toggle, report }) => {
  const [selectedSplit, setSelectedSplit] = useState(null);
  const [internalSplitList, setInternalSplitList] = useState(report?.splits ?? { list: {}, order: [] });

  useEffect(() => {
    setInternalSplitList(state =>
      produce(state, draft => {
        draft.order = report?.splits?.order;
        draft.list = report?.splits?.list;
      })
    );
  }, [report?.splits]);

  const handler = {
    close: () => {
      setSelectedSplit(null);
      toggle();
    },
    setSelectedSplit,
    setInternalSplitList,
    createSplit: () => {
      const split = {
        ...INITAL_SPLIT_STATE,
        id: 'new'
      };
      if (internalSplitList.list) {
        setInternalSplitList(state =>
          produce(state, draft => {
            draft.order.push('new');
            draft.list['new'] = split;
          })
        );
      } else {
        const _splits = {
          list: { new: split },
          order: ['new']
        };
        setInternalSplitList(_splits);
      }
      setSelectedSplit('new');
    },
    saveSplit: split => {
      if (split.id === 'new') {
        reportsActions.addSplit(report.id, { ...split, id: undefined });
      } else {
        reportsActions.updateSplit(report.id, split.id, split);
      }
    },
    deleteSplit: splitid => {
      reportsActions.deleteSplit(report.id, splitid);
    }
  };

  return (
    <Modal className={'edit-filter-modal'} isOpen={isOpen} toggle={handler.close} size={'xl'}>
      {!selectedSplit && <DynamicSplitsList handler={handler} internalSplitList={internalSplitList} report={report} />}
      {!!selectedSplit && (
        <DynamicSplitConfig
          handler={handler}
          report={report}
          selectedSplit={selectedSplit}
          internalSplitList={internalSplitList}
        />
      )}
    </Modal>
  );
};

export const EditDynamicSplitsButtonAndModal = ({ report, buttonClassName = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(b => !b);

  return (
    <>
      <EditDynamicSplitsButton toggle={toggle} className={buttonClassName} />
      {isOpen && <EditDynamicSplitsModal toggle={toggle} isOpen={isOpen} report={report} />}
    </>
  );
};
