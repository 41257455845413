export const reportsActionTypes = {
  LOAD_REPORTS_REQUEST: 'LOAD_REPORTS_REQUEST',
  LOAD_REPORTS_SUCCESS: 'LOAD_REPORTS_SUCCESS',
  LOAD_REPORTS_FAILURE: 'LOAD_REPORTS_FAILURE',

  ADD_REPORT_REQUEST: 'ADD_REPORT_REQUEST',
  ADD_REPORT_SUCCESS: 'ADD_REPORT_SUCCESS',
  ADD_REPORT_FAILURE: 'ADD_REPORT_FAILURE',

  DELETE_REPORT_REQUEST: 'DELETE_REPORT_REQUEST',
  DELETE_REPORT_SUCCESS: 'DELETE_REPORT_SUCCESS',
  DELETE_REPORT_FAILURE: 'DELETE_REPORT_FAILURE',

  DUPLICATE_REPORT_REQUEST: 'DUPLICATE_REPORT_REQUEST',
  DUPLICATE_REPORT_SUCCESS: 'DUPLICATE_REPORT_SUCCESS',
  DUPLICATE_REPORT_FAILURE: 'DUPLICATE_REPORT_FAILURE',

  UPDATE_REPORT_REQUEST: 'UPDATE_REPORT_REQUEST',
  UPDATE_REPORT_SUCCESS: 'UPDATE_REPORT_SUCCESS',
  UPDATE_REPORT_FAILURE: 'UPDATE_REPORT_FAILURE',

  CREATE_REPORT_PAGE_REQUEST: 'CREATE_REPORT_PAGE_REQUEST',
  CREATE_REPORT_PAGE_SUCCESS: 'CREATE_REPORT_PAGE_SUCCESS',
  CREATE_REPORT_PAGE_FAILURE: 'CREATE_REPORT_PAGE_FAILURE',

  UPDATE_REPORT_PAGE_REQUEST: 'UPDATE_REPORT_PAGE_REQUEST',
  UPDATE_REPORT_PAGE_SUCCESS: 'UPDATE_REPORT_PAGE_SUCCESS',
  UPDATE_REPORT_PAGE_FAILURE: 'UPDATE_REPORT_PAGE_FAILURE',

  DELETE_REPORT_PAGE_REQUEST: 'DELETE_REPORT_PAGE_REQUEST',
  DELETE_REPORT_PAGE_SUCCESS: 'DELETE_REPORT_PAGE_SUCCESS',
  DELETE_REPORT_PAGE_FAILURE: 'DELETE_REPORT_PAGE_FAILURE',

  UPDATE_REPORT_PAGE_STATIC_FILTER_REQUEST: 'UPDATE_REPORT_PAGE_STATIC_FILTER_REQUEST',
  UPDATE_REPORT_PAGE_STATIC_FILTER_SUCCESS: 'UPDATE_REPORT_PAGE_STATIC_FILTER_SUCCESS',
  UPDATE_REPORT_PAGE_STATIC_FILTER_FAILURE: 'UPDATE_REPORT_PAGE_STATIC_FILTER_FAILURE',

  DELETE_REPORT_PAGE_STATIC_FILTER_REQUEST: 'DELETE_REPORT_PAGE_STATIC_FILTER_REQUEST',
  DELETE_REPORT_PAGE_STATIC_FILTER_SUCCESS: 'DELETE_REPORT_PAGE_STATIC_FILTER_SUCCESS',
  DELETE_REPORT_PAGE_STATIC_FILTER_FAILURE: 'DELETE_REPORT_PAGE_STATIC_FILTER_FAILURE',

  UPDATE_LAYOUT_REQUEST: 'UPDATE_LAYOUT_REQUEST',
  UPDATE_LAYOUT_SUCCESS: 'UPDATE_LAYOUT_SUCCESS',
  UPDATE_LAYOUT_FAILURE: 'UPDATE_LAYOUT_FAILURE',
  CREATE_REPORT_FILTER_REQUEST: 'CREATE_REPORT_FILTER_REQUEST',
  CREATE_REPORT_FILTER_SUCCESS: 'CREATE_REPORT_FILTER_SUCCESS',
  CREATE_REPORT_FILTER_FAILURE: 'CREATE_REPORT_FILTER_FAILURE',

  UPDATE_REPORT_FILTER_REQUEST: 'UPDATE_REPORT_FILTER_REQUEST',
  UPDATE_REPORT_FILTER_SUCCESS: 'UPDATE_REPORT_FILTER_SUCCESS',
  UPDATE_REPORT_FILTER_FAILURE: 'UPDATE_REPORT_FILTER_FAILURE',

  DELETE_REPORT_FILTER_REQUEST: 'DELETE_REPORT_FILTER_REQUEST',
  DELETE_REPORT_FILTER_SUCCESS: 'DELETE_REPORT_FILTER_SUCCESS',
  DELETE_REPORT_FILTER_FAILURE: 'DELETE_REPORT_FILTER_FAILURE',

  CREATE_REPORT_PAGE_ELEMENT_REQUEST: 'CREATE_REPORT_PAGE_ELEMENT_REQUEST',
  CREATE_REPORT_PAGE_ELEMENT_SUCCESS: 'CREATE_REPORT_PAGE_ELEMENT_SUCCESS',
  CREATE_REPORT_PAGE_ELEMENT_FAILURE: 'CREATE_REPORT_PAGE_ELEMENT_FAILURE',

  DELETE_REPORT_PAGE_ELEMENT_REQUEST: 'DELETE_REPORT_PAGE_ELEMENT_REQUEST',
  DELETE_REPORT_PAGE_ELEMENT_SUCCESS: 'DELETE_REPORT_PAGE_ELEMENT_SUCCESS',
  DELETE_REPORT_PAGE_ELEMENT_FAILURE: 'DELETE_REPORT_PAGE_ELEMENT_FAILURE',

  COPY_REPORT_PAGE_ELEMENT_REQUEST: 'COPY_REPORT_PAGE_ELEMENT_REQUEST',
  COPY_REPORT_PAGE_ELEMENT_SUCCESS: 'COPY_REPORT_PAGE_ELEMENT_SUCCESS',
  COPY_REPORT_PAGE_ELEMENT_FAILURE: 'COPY_REPORT_PAGE_ELEMENT_FAILURE',

  UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_REQUEST: 'UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_REQUEST',
  UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_SUCCESS: 'UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_SUCCESS',
  UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_FAILURE: 'UPDATE_REPORT_PAGE_ELEMENT_CHART_CONFIG_FAILURE',

  UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_REQUEST: 'UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_REQUEST',
  UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_SUCCESS: 'UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_SUCCESS',
  UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_FAILURE: 'UPDATE_REPORT_PAGE_ELEMENT_MEDIA_CONFIG_FAILURE',

  ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_REQUEST: 'ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_REQUEST',
  ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_SUCCESS: 'ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_SUCCESS',
  ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_FAILURE: 'ADD_REPORT_PAGE_ELEMENT_DISABLED_FILTER_FAILURE',

  REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_REQUEST: 'REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_REQUEST',
  REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_SUCCESS: 'REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_SUCCESS',
  REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_FAILURE: 'REMOVE_REPORT_PAGE_ELEMENT_DISABLED_FILTER_FAILURE',

  ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_REQUEST: 'ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_REQUEST',
  ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_SUCCESS: 'ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_SUCCESS',
  ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_FAILURE: 'ADD_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_FAILURE',

  REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_REQUEST: 'REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_REQUEST',
  REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_SUCCESS: 'REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_SUCCESS',
  REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_FAILURE: 'REMOVE_REPORT_PAGE_ELEMENT_ENABLED_SPLIT_FAILURE',
  /**
   * REPORT FILTER
   */
  SAVE_FILTER_REQUEST: 'SAVE_FILTER_REQUEST',
  SAVE_FILTER_SUCCESS: 'SAVE_FILTER_SUCCESS',
  SAVE_FILTER_FAILURE: 'SAVE_FILTER_FAILURE',

  DELETE_FILTER_REQUEST: 'DELETE_FILTER_REQUEST',
  DELETE_FILTER_SUCCESS: 'DELETE_FILTER_SUCCESS',
  DELETE_FILTER_FAILURE: 'DELETE_FILTER_FAILURE',

  REPOSITION_FILTER_REQUEST: 'REPOSITION_FILTER_REQUEST',
  REPOSITION_FILTER_SUCCESS: 'REPOSITION_FILTER_SUCCESS',
  REPOSITION_FILTER_FAILURE: 'REPOSITION_FILTER_FAILURE',

  /**
   * REPORT SPLITS
   */
  REPORT_ADD_SPLIT_REQUEST: 'REPORT_ADD_SPLIT_REQUEST',
  REPORT_ADD_SPLIT_SUCCESS: 'REPORT_ADD_SPLIT_SUCCESS',
  REPORT_ADD_SPLIT_FAILURE: 'REPORT_ADD_SPLIT_FAILURE',

  REPORT_UPDATE_SPLIT_REQUEST: 'REPORT_UPDATE_SPLIT_REQUEST',
  REPORT_UPDATE_SPLIT_SUCCESS: 'REPORT_UPDATE_SPLIT_SUCCESS',
  REPORT_UPDATE_SPLIT_FAILURE: 'REPORT_UPDATE_SPLIT_FAILURE',

  REPORT_DELETE_SPLIT_REQUEST: 'REPORT_DELETE_SPLIT_REQUEST',
  REPORT_DELETE_SPLIT_SUCCESS: 'REPORT_DELETE_SPLIT_SUCCESS',
  REPORT_DELETE_SPLIT_FAILURE: 'REPORT_DELETE_SPLIT_FAILURE',

  REPOSITION_SPLIT_REQUEST: 'REPOSITION_SPLIT_REQUEST',
  REPOSITION_SPLIT_SUCCESS: 'REPOSITION_SPLIT_SUCCESS',
  REPOSITION_SPLIT_FAILURE: 'REPOSITION_SPLIT_FAILURE',

  /**
   * SHARED REPORTS
   */
  SAVE_SHARED_REPORT_REQUEST: 'SAVE_SHARED_REPORT_REQUEST',
  SAVE_SHARED_REPORT_SUCCESS: 'SAVE_SHARED_REPORT_SUCCESS',
  SAVE_SHARED_REPORT_FAILURE: 'SAVE_SHARED_REPORT_FAILURE',

  DELETE_SHARED_REPORT_REQUEST: 'DELETE_SHARED_REPORT_REQUEST',
  DELETE_SHARED_REPORT_SUCCESS: 'DELETE_SHARED_REPORT_SUCCESS',
  DELETE_SHARED_REPORT_FAILURE: 'DELETE_SHARED_REPORT_FAILURE',

  // --- c&a pagecontent list logic start ---
  LOAD_REPORTPAGE_FOLDERS_REQUEST: 'LOAD_REPORTPAGE_FOLDERS_REQUEST',
  LOAD_REPORTPAGE_FOLDERS_SUCCESS: 'LOAD_REPORTPAGE_FOLDERS_SUCCESS',
  LOAD_REPORTPAGE_FOLDERS_FAILURE: 'LOAD_REPORTPAGE_FOLDERS_FAILURE',

  CREATE_REPORTPAGE_FOLDER_REQUEST: 'CREATE_REPORTPAGE_FOLDER_REQUEST',
  CREATE_REPORTPAGE_FOLDER_SUCCESS: 'CREATE_REPORTPAGE_FOLDER_SUCCESS',
  CREATE_REPORTPAGE_FOLDER_FAILURE: 'CREATE_REPORTPAGE_FOLDER_FAILURE',

  DELETE_REPORTPAGE_FOLDER_REQUEST: 'DELETE_REPORTPAGE_FOLDER_REQUEST',
  DELETE_REPORTPAGE_FOLDER_SUCCESS: 'DELETE_REPORTPAGE_FOLDER_SUCCESS',
  DELETE_REPORTPAGE_FOLDER_FAILURE: 'DELETE_REPORTPAGE_FOLDER_FAILURE',

  UPDATE_REPORTPAGE_FOLDER_REQUEST: 'UPDATE_REPORTPAGE_FOLDER_REQUEST',
  UPDATE_REPORTPAGE_FOLDER_SUCCESS: 'UPDATE_REPORTPAGE_FOLDER_SUCCESS',
  UPDATE_REPORTPAGE_FOLDER_FAILIURE: 'UPDATE_REPORTPAGE_FOLDER_FAILIURE'
  // --- c&a pagecontent list logic end ---
};
