import React, { useCallback, useState, useMemo } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { useIntl } from 'react-intl';

import { TextInputRow } from 'smv-components';

export const AddPageButton = ({ toggle }) => {
  return (
    <li className='smv-tabs-container__tab-nav-newtab' aria-label='Add tab' onClick={toggle}>
      <span aria-hidden='true'>
        <i className='fal fa-plus'></i>
      </span>
    </li>
  );
};

export const AddPageModal = ({ isOpen, toggle, reportsHandler }) => {
  const initial_page = useMemo(() => {
    return { name: '' };
  }, []);
  const [internalPage, setInternalPage] = useState(initial_page);

  const intl = useIntl();

  const updateName = useCallback(({ target }) => {
    const { value } = target;
    setInternalPage(state => ({ ...state, name: value }));
  }, []);

  const saveHandler = useCallback(
    event => {
      event.preventDefault();

      reportsHandler.addPage({ ...internalPage }).then(res => {
        setInternalPage(initial_page);
        toggle();
      });
    },
    [internalPage, initial_page, toggle, reportsHandler]
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} autoFocus={false}>
      <Form onSubmit={saveHandler}>
        <ModalHeader toggle={toggle}>
          {intl.formatMessage({
            id: `smoove.page.survey.reports.form.add-page.headline`,
            defaultMessage: 'Edit Page'
          })}
        </ModalHeader>
        <ModalBody>
          {/* <Row>
            <Col>
              {intl.formatMessage({
                id: `smoove.page.survey.reports.form.edit-page.text`,
                defaultMessage: 'Here you can edit the pages configuration, like the title, conditional display e.g.'
              })}
            </Col>
          </Row> */}
          <TextInputRow
            name={'title'}
            title={intl.formatMessage({
              id: `smoove.page.survey.reports.form.edit-page.fields.title.label`,
              defaultMessage: 'Title'
            })}
            tooltip={intl.formatMessage({
              id: `smoove.page.survey.reports.form.edit-page.fields.title.desc`,
              defaultMessage: 'The title is displayed in the selection menu or tabs.'
            })}
            value={internalPage.name}
            change={updateName}
            placeholder={intl.formatMessage({
              id: `smoove.page.survey.reports.form.edit-page.fields.title.placeholder`,
              defaultMessage: 'enter page title...'
            })}
            autoFocus={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={saveHandler} disabled={!internalPage.name}>
            {intl.formatMessage({ id: 'smoove.common.buttons.save' })}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const AddPageButtonAndModal = ({ reportsHandler }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(b => !b);

  return (
    <>
      <AddPageButton toggle={toggle} />
      <AddPageModal toggle={toggle} isOpen={isOpen} reportsHandler={reportsHandler} />
    </>
  );
};
