import { getRowOrder } from 'smv-helpers';
import { getHeadBaseValue } from '../helper';
import { ResultTableChartCell } from './ResultTableChartCell';
import {
  getConditionalFormat,
  useDefaultMetric
} from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

export const ResultTableChartRowTransposedTotal = ({ table, tableResult, chartConfig, hover }) => {
  const { showBase = true } = chartConfig;
  const defaultMetric = useDefaultMetric({ config: table.config });

  const rowColumns = [];

  if (showBase) {
    rowColumns.push(
      <td key={`base-cell-total`} className={'small font-italic'}>
        {getHeadBaseValue(tableResult, '__total__', '__total__', 'smvrslt_base')}
      </td>
    );
  }

  table.rows.order.forEach(rowId => {
    const row = table.rows.list[rowId];
    const { rowOrder: headOrder } = getRowOrder(tableResult?.order, row);

    let metric = defaultMetric;
    if (row.sourceType === 'calculation') metric = 'smvrslt_calculation';

    headOrder.forEach(orderItem => {
      const isHidden = orderItem?.hidden ?? false;
      const isExcluded = orderItem?.excluded ?? false;

      if (isHidden || isExcluded) return null;

      const applyConditionalFormat = getConditionalFormat({
        conditionalFormats: chartConfig.conditionalFormats,
        tableResult,
        rowId,
        rowSubId: orderItem.subelement,
        rowSubSubId: orderItem.subsubelement,
        headId: '__total__',
        headSubId: '__total__',
        metric: metric,
        defaultMetric
      });

      rowColumns.push(
        <ResultTableChartCell
          key={`cell_total_${orderItem.rowid}_${[orderItem.subelement, orderItem.subsubelement]
            .filter(Boolean)
            .join('_')}`}
          cell={getResultCell(
            tableResult,
            orderItem.rowid,
            orderItem.subelement,
            orderItem?.subsubelement,
            '__total__',
            '__total__'
          )}
          metric={metric}
          config={table.config}
          cellIdentifier={{
            rowId: orderItem.rowid,
            rowSubId: orderItem.subelement,
            rowSubSubId: orderItem?.subsubelement ?? null,
            headId: '__total__',
            headSubId: '__total__'
          }}
          hover={hover}
          significancesShow={chartConfig?.significancesShow ?? false}
          conditionalFormat={applyConditionalFormat}
        />
      );
    });
  });

  return (
    <tr key={`table-row-total`} className={'result__row'}>
      {chartConfig.showQuestionTitleColumn && <th></th>}
      {<th id={'table-row-total'}>Total</th>}
      {rowColumns}
    </tr>
  );
};

const getResultCell = (tableResult, rowId, rowSubId, rowSubSubId, headId, headSubId) => {
  if (rowSubSubId) return tableResult?.values?.[rowId]?.[rowSubId]?.[rowSubSubId]?.[headId]?.[headSubId];
  else return tableResult?.values?.[rowId]?.[rowSubId]?.[headId]?.[headSubId];
};
