import Axios from 'axios';
import { Store as notify } from 'react-notifications-component';
import { defaultNotifyProps } from 'smv-constants';
import { store } from 'smv-redux';
import { questionnaireActionTypes } from '../constants/questionnaireConstants';
import { downloadFile } from './helper';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

/**
 * Loads a specific questionnaire
 * @param {string} surveyid
 */
const load = surveyid => {
  store.dispatch({
    type: questionnaireActionTypes.LOAD_QUESTIONNAIRE_REQUEST,
    payload: surveyid
  });

  Axios.get(`/surveys/${surveyid}/questionnaire`)
    .then(res => {
      const questionnaire = res.data;
      console.log(questionnaire);
      store.dispatch({
        type: questionnaireActionTypes.LOAD_QUESTIONNAIRE_SUCCESS,
        payload: {
          questionnaire,
          surveyid
        }
      });
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: questionnaireActionTypes.LOAD_QUESTIONNAIRE_FAILURE,
        payload: {
          surveyid,
          error: err
        }
      });
    });
};

/**
 * Adds an element to a questionnaire
 * @param {string} surveyid
 * @param {object} data //data of the newly created element
 */
const addQuestionnaireElement = async (surveyId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_QUESTIONNAIRE_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/questionnaire/elements`, data)
    .then(res => {
      const questionnaire = res.data;
      console.log(questionnaire);
      store.dispatch({
        type: questionnaireActionTypes.ADD_QUESTIONNAIRE_ELEMENT_SUCCESS,
        payload: {
          questionnaire,
          surveyId
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_QUESTIONNAIRE_ELEMENT_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * Removes an element from the questionnaire
 * @param {string} surveyid
 * @param {string} elementId
 */
const removeQuestionnaireElement = async (surveyId, elemenId) => {
  store.dispatch({
    type: questionnaireActionTypes.REMOVE_QUESTIONNAIRE_ELEMENT_REQUEST,
    payload: elemenId
  });

  return Axios.delete(`/surveys/${surveyId}/questionnaire/elements/${elemenId}`)
    .then(res => {
      const questionnaire = res.data;
      console.log(questionnaire);
      store.dispatch({
        type: questionnaireActionTypes.REMOVE_QUESTIONNAIRE_ELEMENT_SUCCESS,
        payload: {
          questionnaire,
          surveyId
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.REMOVE_QUESTIONNAIRE_ELEMENT_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * Updates an element from the questionnaire
 * @param {string} surveyid
 * @param {string} elementId
 * @param {object} data
 */
const updateQuestionnaireElement = async (surveyId, elementId, data) => {
  console.log(data);
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_QUESTIONNAIRE_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return new Promise((resolve, reject) => {
    return Axios.patch(`/surveys/${surveyId}/questionnaire/elements/${elementId}`, data)
      .then(res => {
        const questionnaire = res.data;

        store.dispatch({
          type: questionnaireActionTypes.UPDATE_QUESTIONNAIRE_ELEMENT_SUCCESS,
          payload: {
            questionnaire,
            surveyId
          }
        });

        resolve(res);
        return res;
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_QUESTIONNAIRE_ELEMENT_FAILURE,
          payload: {
            surveyId,
            error
          }
        });

        notify.addNotification({
          title: parseErrorAsMessageString(error),
          type: 'danger',
          ...defaultNotifyProps
        });
        reject(error);
        return error;
      });
  });
};

/**
 * Copies a questionnaire element and appends it below the selected element.
 *
 * @param {string} surveyid Survey id
 * @param {string} elementid Id of element to copy
 */
const copyQuestionnaireElement = async (surveyId, elemenId) => {
  store.dispatch({
    type: questionnaireActionTypes.COPY_QUESTIONNAIRE_ELEMENT_REQUEST,
    payload: elemenId
  });

  return Axios.request({
    method: 'copy',
    url: `/surveys/${surveyId}/questionnaire/elements/${elemenId}`
  })
    .then(res => {
      const data = res.data;
      const survey = data.survey;
      const questionnaire = data.questionnaire;

      store.dispatch({
        type: questionnaireActionTypes.COPY_QUESTIONNAIRE_ELEMENT_SUCCESS,
        payload: {
          survey,
          questionnaire,
          surveyId
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.COPY_QUESTIONNAIRE_ELEMENT_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * Adds a choice to a question
 *
 * @param {string} surveyid Survey ID
 * @param {string} questionId Question ID
 * @param {choice: object, translations: object} data Object with choice element
 *  & choice label main translation, e.g.
 * {
 *    choice: {         // choice element
 *      type: 'normal',
 *      position: 1,
 *      value: 1,
 *      config: {
 *        randomize: false
 *      }
 *    },
 *    translations: {   // choice label translation
 *      'de_DE': 'Choice label'
 *    }
 * }
 */
const addChoice = async (surveyId, questionId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_CHOICE_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/questionnaire/elements/${questionId}/choice`, data)
    .then(async res => {
      const data = res.data;
      const questionnaire = data.questionnaire;
      const translations = data.translations;

      store.dispatch({
        type: questionnaireActionTypes.ADD_CHOICE_SUCCESS,
        payload: {
          questionId,
          surveyId,
          questionnaire,
          translations
        }
      });

      return questionnaire;
    })

    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_CHOICE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * Adds list of choices to a question (per copy/paste)
 *
 * @param {string} surveyid Survey ID
 * @param {string} questionId Question ID
 * @param {array<number, {choice: object, translations: object}>} data List of
 *  objects with choice element & choice label main translation, e.g.
 *  [
 *    {
 *      choice: {         // choice element
 *        type: 'normal',
 *        position: 1,
 *        value: 1,
 *        config: {
 *          randomize: false
 *        }
 *      },
 *      translations: {   // choice label translation
 *        'de_DE': 'Choice label'
 *      }
 *    }, ...
 *  ]
 */
const addChoices = async (surveyId, questionId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_CHOICE_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/questionnaire/elements/${questionId}/choices`, data)
    .then(async res => {
      const data = res.data;
      const questionnaire = data.questionnaire;
      const translations = data.translations;

      store.dispatch({
        type: questionnaireActionTypes.ADD_CHOICE_SUCCESS,
        payload: {
          questionId,
          surveyId,
          questionnaire,
          translations
        }
      });

      return questionnaire;
    })

    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_CHOICE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * updates a choice of a question
 * @param {string} surveyid
 * @param {string} questionnaireElementId
 * @param {string} choiceId
 * @param {object} data
 * @param {function} callbackOnError //function that is called on error (helpful to reset state)
 *
 */
const updateChoice = async (surveyId, questionId, choiceId, data, callbackOnError = () => {}) => {
  if (Object.keys(data).length > 0) {
    store.dispatch({
      type: questionnaireActionTypes.UPDATE_CHOICE_REQUEST,
      payload: { ...data }
    });

    return Axios.patch(`/surveys/${surveyId}/questionnaire/elements/${questionId}/choices/${choiceId}`, data)
      .then(res => {
        const questionnaire = res.data;
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_CHOICE_SUCCESS,
          payload: {
            questionnaire,
            surveyId
          }
        });

        return res;
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_CHOICE_FAILURE,
          payload: {
            surveyId,
            error
          }
        });

        notify.addNotification({
          title: parseErrorAsMessageString(error),
          type: 'danger',
          ...defaultNotifyProps
        });

        callbackOnError();

        return error;
      });
  }
};

/**
 * Updates all choices of a question
 *
 * @param {string} surveyid
 * @param {string} questionnaireElementId
 * @param {object} data
 * @param {function} callbackOnError //function that is called on error (helpful to reset state)
 *
 */
const updateAllChoices = async (surveyId, questionId, data, callbackOnError = () => {}) => {
  if (Object.keys(data).length > 0) {
    store.dispatch({
      type: questionnaireActionTypes.UPDATE_ALL_CHOICES_REQUEST,
      payload: { ...data }
    });

    return Axios.patch(`/surveys/${surveyId}/questionnaire/elements/${questionId}/choices`, data)
      .then(res => {
        const question = res.data;
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_ALL_CHOICES_SUCCESS,
          payload: {
            surveyId,
            question
          }
        });

        return question;
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_ALL_CHOICES_FAILURE,
          payload: {
            surveyId,
            error
          }
        });

        notify.addNotification({
          title: parseErrorAsMessageString(error),
          type: 'danger',
          ...defaultNotifyProps
        });

        callbackOnError();

        return error;
      });
  }
};

/**
 * removes a choice from a question
 * @param {string} surveyid
 * @param {string} questionId
 * @param {string} choiceId
 *
 */
const removeChoice = async (surveyId, questionId, choiceId) => {
  store.dispatch({
    type: questionnaireActionTypes.REMOVE_CHOICE_REQUEST,
    payload: { surveyId, questionId, choiceId }
  });

  return Axios.delete(`/surveys/${surveyId}/questionnaire/elements/${questionId}/choices/${choiceId}`)
    .then(res => {
      const questionnaire = res.data;
      console.log(questionnaire);
      store.dispatch({
        type: questionnaireActionTypes.REMOVE_CHOICE_SUCCESS,
        payload: {
          questionnaire,
          questionId,
          surveyId,
          choiceId
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.REMOVE_CHOICE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * updates an OpenEnd choice of a question
 * @param {string} surveyid
 * @param {string} questionnaireElementId
 * @param {string} choiceId
 * @param {object} data
 *
 */
const updateChoiceOpenEnd = async (surveyId, questionnaireElementId, choiceId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_CHOICE_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(
    `/surveys/${surveyId}/questionnaire/elements/${questionnaireElementId}/choices/${choiceId}/openend`,
    data
  )
    .then(res => {
      const questionnaire = res.data;
      store.dispatch({
        type: questionnaireActionTypes.UPDATE_CHOICE_SUCCESS,
        payload: {
          questionnaire,
          surveyId
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.UPDATE_CHOICE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * deletes OpenEnd settings of a choice of a question
 * @param {string} surveyid
 * @param {string} questionnaireElementId
 * @param {string} choiceId
 *
 */
const deleteChoiceOpenEnd = async (surveyId, questionnaireElementId, choiceId) => {
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_CHOICE_REQUEST,
    payload: {}
  });

  return Axios.delete(
    `/surveys/${surveyId}/questionnaire/elements/${questionnaireElementId}/choices/${choiceId}/openend`
  )
    .then(res => {
      const questionnaire = res.data;
      store.dispatch({
        type: questionnaireActionTypes.UPDATE_CHOICE_SUCCESS,
        payload: {
          questionnaire,
          surveyId
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.UPDATE_CHOICE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * updates an imported choice of a question
 * @param {string} surveyid
 * @param {string} questionId
 * @param {object} data
 *
 */
const updateChoicesImport = async (surveyId, questionId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_CHOICES_IMPORT_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/questionnaire/elements/${questionId}/choices/import`, data)
    .then(async res => {
      const questionnaire = res.data;

      store.dispatch({
        type: questionnaireActionTypes.UPDATE_CHOICES_IMPORT_SUCCESS,
        payload: {
          questionId,
          surveyId,
          questionnaire
        }
      });

      return questionnaire;
    })

    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.UPDATE_CHOICES_IMPORT_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * removes an imported choice from a question
 * @param {string} surveyid
 * @param {string} questionId
 * @param {object} data
 *
 */
const removeChoicesImport = async (surveyId, questionId) => {
  store.dispatch({
    type: questionnaireActionTypes.REMOVE_CHOICES_IMPORT_REQUEST,
    payload: { surveyId, questionId }
  });

  return Axios.delete(`/surveys/${surveyId}/questionnaire/elements/${questionId}/choices/import`)
    .then(async res => {
      const questionnaire = res.data;

      store.dispatch({
        type: questionnaireActionTypes.REMOVE_CHOICES_IMPORT_SUCCESS,
        payload: {
          questionId,
          surveyId,
          questionnaire
        }
      });

      return questionnaire;
    })

    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.REMOVE_CHOICES_IMPORT_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * adds a scale to a question
 * @param {string} surveyid
 * @param {string} questionId
 * @param {object} data
 * @param {object} translations // rte-text-content of the selected language {[locale]: quill.root.innerHTML }
 *
 */

/**
 * Adds a scale to a question
 *
 * @param {string} surveyid Survey ID
 * @param {string} questionId Question ID
 * @param {scale: object, translations: object} data Object with scale element
 *  & scale label main translation, e.g.
 * {
 *    scale: {         // scale element
 *      position: 1,
 *      value: 1,
 *    },
 *    translations: {   // scale label translation
 *      'de_DE': 'Scale label'
 *    }
 * }
 */
const addScale = async (surveyId, questionId, data, translations) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_SCALE_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/questionnaire/elements/${questionId}/scale`, data)
    .then(async res => {
      const data = res.data;
      const questionnaire = data.questionnaire;
      const translations = data.translations;

      store.dispatch({
        type: questionnaireActionTypes.ADD_SCALE_SUCCESS,
        payload: {
          questionId,
          surveyId,
          questionnaire,
          translations
        }
      });

      return questionnaire;
    })

    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_SCALE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      return error;
    });
};

/**
 * Adds list of scales to a question (per copy/paste)
 *
 * @param {string} surveyid Survey ID
 * @param {string} questionId Question ID
 * @param {array<number, {scale: object, translations: object}>} data List of
 *  objects with scale element & scale label main translation, e.g.
 *  [
 *    {
 *      scale: {         // scale element
 *        position: 1,
 *        value: 1,
 *      },
 *      translations: {   // scale label translation
 *        'de_DE': 'Scale label'
 *      }
 *    }, ...
 *  ]
 */
const addScales = async (surveyId, questionId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_SCALE_REQUEST,
    payload: { ...data }
  });
  console.log(data);

  return Axios.post(`/surveys/${surveyId}/questionnaire/elements/${questionId}/scales`, data)
    .then(async res => {
      const data = res.data;
      const questionnaire = data.questionnaire;
      const translations = data.translations;

      store.dispatch({
        type: questionnaireActionTypes.ADD_SCALE_SUCCESS,
        payload: {
          questionId,
          surveyId,
          questionnaire,
          translations
        }
      });

      return questionnaire;
    })

    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_SCALE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * updates an scale of a question
 * @param {string} surveyid
 * @param {string} questionnaireElementId
 * @param {string} scaleId
 * @param {object} data
 *
 */
const updateScale = async (surveyId, questionnaireElementId, scaleId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_SCALE_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/questionnaire/elements/${questionnaireElementId}/scales/${scaleId}`, data)
    .then(res => {
      const questionnaire = res.data;
      console.log(questionnaire);
      store.dispatch({
        type: questionnaireActionTypes.UPDATE_SCALE_SUCCESS,
        payload: {
          questionnaire,
          surveyId
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.UPDATE_SCALE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      return error;
    });
};

/**
 * removes an scale from a question
 * @param {string} surveyid
 * @param {string} questionId
 * @param {string} scaleId
 *
 */
const removeScale = async (surveyId, questionId, scaleId) => {
  store.dispatch({
    type: questionnaireActionTypes.REMOVE_SCALE_REQUEST,
    payload: { surveyId, questionId, scaleId }
  });

  return Axios.delete(`/surveys/${surveyId}/questionnaire/elements/${questionId}/scales/${scaleId}`)
    .then(res => {
      const questionnaire = res.data;
      console.log(questionnaire);
      store.dispatch({
        type: questionnaireActionTypes.REMOVE_SCALE_SUCCESS,
        payload: {
          questionnaire,
          questionId,
          surveyId,
          scaleId
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.REMOVE_SCALE_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      return error;
    });
};

/**
 * adds a screenout to a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {object} data
 *
 */
// TODO: Check if we need this function (smvb-760).
// todo: obsolete due to possibility to overwrite the complete screenout via patch in updateScreenoutElement?
const addScreenoutElement = async (surveyId, elementId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_SCREENOUT_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/questionnaire/elements/${elementId}/screenouts`, data)
    .then(res => {
      const screenout = res.data;
      store.dispatch({
        type: questionnaireActionTypes.ADD_SCREENOUT_ELEMENT_SUCCESS,
        payload: {
          surveyId,
          elementId,
          screenout
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_SCREENOUT_ELEMENT_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      return error;
    });
};

/**
 * removes screenout from a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} screenoutElementId
 *
 */
const removeScreenoutElement = async (surveyId, elementId, screenoutElementId) => {
  store.dispatch({
    type: questionnaireActionTypes.REMOVE_SCREENOUT_ELEMENT_REQUEST,
    payload: elementId
  });
  return new Promise((resolve, reject) => {
    Axios.delete(`/surveys/${surveyId}/questionnaire/elements/${elementId}/screenouts/${screenoutElementId}`)
      .then(res => {
        const screenout = res.data;

        store.dispatch({
          type: questionnaireActionTypes.REMOVE_SCREENOUT_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            elementId,
            screenout
          }
        });
        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.REMOVE_SCREENOUT_ELEMENT_FAILURE,
          payload: {
            surveyId,
            error
          }
        });
        reject(error);
      });
  });
};

/**
 * updates a screenout of a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} screenoutElementId
 * @param {object} data
 *
 */
const updateScreenoutElement = async (surveyId, elementId, screenoutId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_SCREENOUT_ELEMENT_REQUEST,
    payload: { ...data }
  });
  return new Promise((resolve, reject) => {
    Axios.patch(`/surveys/${surveyId}/questionnaire/elements/${elementId}/screenouts/${screenoutId}`, {
      ...data,
      id: undefined
    })
      .then(res => {
        const screenout = res.data;
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_SCREENOUT_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            elementId,
            screenout
          }
        });
        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_SCREENOUT_ELEMENT_FAILURE,
          payload: {
            surveyId,
            error
          }
        });
        reject(error);
      });
  });
};

/**
 * adds a filter to a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {object} data
 *
 */
// todo: obsolete due to possibility to overwrite the complete filter via patch in updateFilterElement?
const addFilterElement = async (surveyId, elementId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_FILTER_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/questionnaire/elements/${elementId}/filters`, data)
    .then(res => {
      const filter = res.data;
      store.dispatch({
        type: questionnaireActionTypes.ADD_FILTER_ELEMENT_SUCCESS,
        payload: {
          surveyId,
          elementId,
          filter
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_FILTER_ELEMENT_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      return error;
    });
};

/**
 * removes a filter condition from a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} filterElementId
 *
 */
const removeFilterElement = async (surveyId, elementId, filterElementId) => {
  store.dispatch({
    type: questionnaireActionTypes.REMOVE_FILTER_ELEMENT_REQUEST,
    payload: elementId
  });

  return new Promise((resolve, reject) => {
    Axios.delete(`/surveys/${surveyId}/questionnaire/elements/${elementId}/filters/${filterElementId}`)
      .then(res => {
        const filter = res.data;

        store.dispatch({
          type: questionnaireActionTypes.REMOVE_FILTER_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            elementId,
            filter
          }
        });

        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.REMOVE_FILTER_ELEMENT_FAILURE,
          payload: {
            surveyId,
            error
          }
        });

        reject(error);
      });
  });
};

/**
 * updates a filter of a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} filterElementId
 * @param {object} data
 *
 */
const updateFilterElement = async (surveyId, elementId, filterId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_FILTER_ELEMENT_REQUEST,
    payload: { ...data }
  });
  return new Promise((resolve, reject) => {
    Axios.patch(`/surveys/${surveyId}/questionnaire/elements/${elementId}/filters/${filterId}`, {
      ...data,
      id: undefined
    })
      .then(res => {
        const filter = res.data;
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_FILTER_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            elementId,
            filter
          }
        });

        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_FILTER_ELEMENT_FAILURE,
          payload: {
            surveyId,
            error
          }
        });

        reject(error);
      });
  });
};

// TODO: to check if we need this funtion (smvb-760)
/**
 * adds a jump to a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {object} data
 *
 */
const addJumpElement = async (surveyId, elementId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_JUMP_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/questionnaire/elements/${elementId}/jumps`, data)
    .then(res => {
      const jump = res.data;
      store.dispatch({
        type: questionnaireActionTypes.ADD_JUMP_ELEMENT_SUCCESS,
        payload: {
          surveyId,
          elementId,
          jump
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_JUMP_ELEMENT_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      return error;
    });
};

/**
 * removes a jump from a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} jumpElementId
 *
 */
const removeJumpElement = async (surveyId, elementId, jumpElementId) => {
  store.dispatch({
    type: questionnaireActionTypes.REMOVE_JUMP_ELEMENT_REQUEST,
    payload: elementId
  });

  return new Promise((resolve, reject) => {
    Axios.delete(`/surveys/${surveyId}/questionnaire/elements/${elementId}/jumps/${jumpElementId}`)
      .then(res => {
        const jump = res.data;

        store.dispatch({
          type: questionnaireActionTypes.REMOVE_JUMP_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            elementId,
            jump
          }
        });

        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.REMOVE_JUMP_ELEMENT_FAILURE,
          payload: {
            surveyId,
            error
          }
        });

        reject(error);
      });
  });
};

/**
 * updates a jump of a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} jumpElementId
 * @param {object} data
 *
 */
const updateJumpElement = async (surveyId, elementId, jumpId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_JUMP_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return new Promise((resolve, reject) => {
    return Axios.patch(`/surveys/${surveyId}/questionnaire/elements/${elementId}/jumps/${jumpId}`, {
      ...data,
      id: undefined
    })
      .then(res => {
        const jump = res.data;
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_JUMP_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            elementId,
            jump
          }
        });

        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_JUMP_ELEMENT_FAILURE,
          payload: {
            surveyId,
            error
          }
        });

        reject(error);
      });
  });
};

// TODO To check if we need this function. It is not used currently, updateDisplayConditionElement is used instead.
/**
 * adds a hide condition to a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} choiceId
 * @param {object} data
 *
 */
const addDisplayConditionElement = async (surveyId, elementId, choiceId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.ADD_DISPLAY_CONDITION_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return Axios.post(
    `/surveys/${surveyId}/questionnaire/elements/${elementId}/choices/${choiceId}/displayConditions`,
    data
  )
    .then(res => {
      const displayCondition = res.data;
      store.dispatch({
        type: questionnaireActionTypes.ADD_DISPLAY_CONDITION_ELEMENT_SUCCESS,
        payload: {
          surveyId,
          elementId,
          choiceId,
          displayCondition
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: questionnaireActionTypes.ADD_DISPLAY_CONDITION_ELEMENT_FAILURE,
        payload: {
          surveyId,
          elementId,
          choiceId,
          error
        }
      });

      return error;
    });
};

/**
 * removes a hide condition from a questionnaire element
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} choiceId
 * @param {string} displayCondtionElementId
 *
 */
const removeDisplayConditionElement = async (surveyId, elementId, choiceId, displayCondtionElementId) => {
  store.dispatch({
    type: questionnaireActionTypes.REMOVE_DISPLAY_CONDITION_ELEMENT_REQUEST,
    payload: elementId
  });

  return new Promise((resolve, reject) => {
    Axios.delete(
      `/surveys/${surveyId}/questionnaire/elements/${elementId}/choices/${choiceId}/displayConditions/${displayCondtionElementId}`
    )
      .then(res => {
        const displayCondition = res.data;

        store.dispatch({
          type: questionnaireActionTypes.REMOVE_DISPLAY_CONDITION_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            elementId,
            choiceId,
            displayCondition
          }
        });

        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.REMOVE_DISPLAY_CONDITION_ELEMENT_FAILURE,
          payload: {
            surveyId,
            error
          }
        });

        reject(error);
      });
  });
};

/**
 * updates a hide condition of a questionnaire element choice
 * @param {string} surveyid
 * @param {string} elementId
 * @param {string} choiceId
 * @param {string} displayConditionElementId
 * @param {object} data
 *
 */

const updateDisplayConditionElement = async (surveyId, elementId, choiceId, displayConditionId, data) => {
  store.dispatch({
    type: questionnaireActionTypes.UPDATE_DISPLAY_CONDITION_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return new Promise((resolve, reject) => {
    Axios.patch(
      `/surveys/${surveyId}/questionnaire/elements/${elementId}/choices/${choiceId}/displayConditions/${displayConditionId}`,
      {
        ...data,
        id: undefined
      }
    )
      .then(res => {
        const displayCondition = res.data;
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_DISPLAY_CONDITION_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            elementId,
            choiceId,
            displayCondition
          }
        });

        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: questionnaireActionTypes.UPDATE_DISPLAY_CONDITION_ELEMENT_FAILURE,
          payload: {
            surveyId,
            elementId,
            choiceId,
            error
          }
        });

        reject(error);
      });
  });
};

/**
 * Exports a report/ page or single element and triggers a download.
 *
 * @param {string} surveyid
 * @param {string} reportid
 * @param {string} pageid
 * @param {string} elementid
 * @param {string} exportType Requested export type, possible values are `powerpoint`
 * @param {array} filters
 */
const exportElement = surveyId => {
  const endpoint = `surveys/${surveyId}/questionnaire/export`;

  return Axios.post(
    endpoint,
    {},
    {
      responseType: 'blob',
      timeout: 5 * 60 * 1000
    }
  ).then(res => {
    let filename = 'questionnaire_export.xlsx';

    const contentDispHeader = res.headers?.['content-disposition'] ?? '';
    const filenameMatch = contentDispHeader.match(/filename=([A-Za-z_.]{5,})/);

    if (filenameMatch?.[1] && filenameMatch[1]?.length > 6) filename = filenameMatch[1];

    downloadFile(res.data, filename);
  });
};

export const questionnaireActions = {
  load,
  addQuestionnaireElement,
  removeQuestionnaireElement,
  updateQuestionnaireElement,
  copyQuestionnaireElement,
  addChoice,
  addChoices,
  updateChoice,
  updateAllChoices,
  removeChoice,
  updateChoiceOpenEnd,
  deleteChoiceOpenEnd,
  updateChoicesImport,
  removeChoicesImport,
  addScale,
  addScales,
  updateScale,
  removeScale,
  addScreenoutElement,
  removeScreenoutElement,
  updateScreenoutElement,
  addFilterElement,
  removeFilterElement,
  updateFilterElement,
  addJumpElement,
  removeJumpElement,
  updateJumpElement,
  addDisplayConditionElement,
  removeDisplayConditionElement,
  updateDisplayConditionElement,
  exportElement
};
