import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';

import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';

import './ChangeLogModal.scss';

export const ChangeLogModal = ({ changeLogId, changelog, isOpen, toggle }) => {
  const intl = useIntl();

  const [currentChangeLogId, setCurrentChangeLogId] = useState(changeLogId);

  const currentChanges = changelog[currentChangeLogId];

  const handleNext = () => {
    if (changelog[currentChangeLogId - 1]) {
      setCurrentChangeLogId(currentChangeLogId - 1);
    }
  };

  const handlePrevious = () => {
    if (changelog[currentChangeLogId + 1]) {
      setCurrentChangeLogId(currentChangeLogId + 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'ArrowLeft') {
        document.getElementById('changelog-modal-navigation-btn-previous').click();
      } else if (event.key === 'ArrowRight') {
        document.getElementById('changelog-modal-navigation-btn-next').click();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} centered={true} toggle={toggle} size={'xl'} className={'changelog-modal-container'}>
      <div className='changelog-modal-navigation-container'>
        <div
          id='changelog-modal-navigation-btn-previous'
          className={`changelog-modal-navigation-container__btn-previous ${
            currentChangeLogId === changelog.length - 1
              ? 'changelog-modal-navigation-container__btn-previous--disabled'
              : ''
          }`}
          onClick={handlePrevious}
        >
          <i className='fal fa-arrow-left' />
        </div>

        <div
          id='changelog-modal-navigation-btn-next'
          className={`changelog-modal-navigation-container__btn-next ${
            currentChangeLogId === 0 ? 'changelog-modal-navigation-container__btn-next--disabled' : ''
          }`}
          onClick={handleNext}
        >
          <i className='fal fa-arrow-right' />
        </div>
      </div>
      <ModalHeader toggle={toggle}>{intl.formatMessage({ id: 'smoove.page.changelog.title' })}</ModalHeader>
      <ModalBody>
        <Row className='mt-2'>
          <Col>
            <strong>{intl.formatMessage({ id: 'smoove.page.changelog.version' })}: </strong>
            {currentChanges.version} ({new Date(currentChanges.date).toLocaleDateString()})
          </Col>
        </Row>
        <ListGroup flush className='mt-2'>
          {currentChanges.changes.map((change, index) => (
            <ListGroupItem key={index}>{change}</ListGroupItem>
          ))}
        </ListGroup>
      </ModalBody>
      <ModalFooter>
        <Button color={'grey'} onClick={toggle}>
          {intl.formatMessage({ id: 'smoove.common.buttons.close' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
