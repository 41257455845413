export const TABLE_INITIAL_STATE = {
  id: '__tmp',
  name: '',
  filters: {},
  heads: { list: {}, order: [] },
  rows: { list: {}, order: [] },
  config: {
    showBase: true,
    showBaseWeighted: false,
    showTotal: true,
    showCodeValuesHead: false,
    showCodeValuesRow: false,
    showHiddenRows: true,
    showHiddenColumns: true,
    valueType: 'perc',
    valuePrecision: 0,
    valueSignificances: false,
    valueSignificancesLevel: 0.95,
    valueSignificancesDimension: 'heads',
    valueSignificancesBenchmark: null,
    valueForcePrecision: false,
    // valueDelimiterLocale: ','
    weightResult: false,
    weightVariable: '',

    sortation: {
      headElemId: '__value__',
      headChoiceId: '__value__',
      direction: 'asc'
    }
  },
  chart: {
    chartType: 'bar',
    chartOrientation: 'horizontal',
    chartSubtype: 'grouped',
    colorsFixed: [],
    showBase: true,
    tableColumnWidthAuto: true,
    tableColumnWidth: 40,
    valuesShow: true,
    showQuestionTitleColumn: false
  }
};
