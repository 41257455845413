import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { getQuestionnaireElementsOrder } from 'smv-helpers';
import { projectSettingsActions, reportsActions, tablesActions } from 'smv-redux';

import { ColorScale } from '../ChartConfig';

import { asyncCreateCharts } from './asyncCreateCharts';
import { asyncCreateTables } from './asyncCreateTables';

import './TabulationAssistant.scss';

export const TabulationAssistant = ({ readonly = false }) => {
  const history = useHistory();
  const intl = useIntl();

  const TOTAL_STEPS_COUNT = 5;
  // steps:
  // 0: not running
  // 1: creating folder
  // 2: creating tables for each question
  // 3: creating new report & page
  // 4. creating chart for each table
  // 5. done

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assistantStatus, setAssistantStatus] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [tablesCount, setTablesCount] = useState(0);
  const [chartsCount, setChartsCount] = useState(0);
  const [newReportId, setNewReportId] = useState(null);
  const [selectedColorScheme, setSelectedColorScheme] = useState('viridis');
  const [folderName, setFolderName] = useState('');

  const folderNamePlaceholder = 'Automatically Generated Tables';

  const isAssistantRunning = !hasError && (assistantStatus <= 0 || assistantStatus >= TOTAL_STEPS_COUNT) ? false : true;
  const isAssistantFinished = assistantStatus === TOTAL_STEPS_COUNT;

  const toggleModal = useCallback(() => {
    const resetValues = () => {
      setAssistantStatus(0);
      setTablesCount(0);
      setHasError(false);
      setChartsCount(0);
    };

    if (isModalOpen) {
      setIsModalOpen(false);
      if (!isAssistantRunning) {
        resetValues();
      }
    } else {
      setIsModalOpen(true);
      if (!isAssistantRunning && !isAssistantFinished) {
        resetValues();
      }
    }
  }, [isAssistantRunning, isModalOpen, isAssistantFinished]);

  const questionnaire = useSelector(state => state.survey.questionnaire);
  const projectId = useSelector(state => state.project.id);
  const surveyId = useSelector(state => state.survey.id);
  const folders = useSelector(state => state.survey.tablesFolders);
  const allQuestionnaireElements = getQuestionnaireElementsOrder(questionnaire);

  const allQuestions = allQuestionnaireElements.filter(qid => {
    const element = questionnaire.list[qid];
    return element && element.type !== 'page' && element.type !== 'container' && element.config.qtype !== 'custom_text';
  });

  const handleRunAssistant = () => {
    // create table folder
    setAssistantStatus(1);
    tablesActions
      .createFolder(surveyId, {
        name: Boolean(folderName) ? folderName : folderNamePlaceholder,
        parentId: folders.rootFolderId
      })
      .then(res => {
        const newTableFolderId = res.id;
        // creating tables for each question
        setAssistantStatus(2);
        asyncCreateTables(newTableFolderId, allQuestions, questionnaire, surveyId, setTablesCount, selectedColorScheme)
          .then(res => {
            const newTables = res;
            setAssistantStatus(3);
            // load new tables folders
            tablesActions
              .loadFolders(surveyId)
              .then(res => {
                const allFolders = res;
                const newTableFolder = allFolders.find(el => el.id === newTableFolderId);
                if (!newTableFolder || !newTables) {
                  return;
                }
                // creating new report
                const reportData = {
                  name: Boolean(folderName) ? folderName : folderNamePlaceholder
                };
                reportsActions
                  .addReport(surveyId, reportData)
                  .then(report => {
                    setAssistantStatus(4);
                    const pageData = {
                      name: 'Page 1'
                    };
                    reportsActions.addReportPage(report.id, pageData).then(res => {
                      const pageId = res.pageId;
                      const report = res.report;
                      // create charts
                      asyncCreateCharts(newTables, report, pageId, setChartsCount)
                        .then(res => {
                          // done!!!
                          setAssistantStatus(5);
                          setNewReportId(report.id);
                        })
                        .catch(err => {
                          setHasError(true);
                          console.log('error creating charts');
                        });
                    });
                  })
                  .catch(err => {
                    setHasError(true);
                    console.log('error creating report');
                  });
              })
              .catch(err => {
                setHasError(true);
                console.log('error loading new folder');
              });
          })
          .catch(err => {
            setHasError(true);
            console.log('error creating tabes');
          });
      })
      .catch(err => {
        setHasError(true);
        console.log('could not create new folder');
      });
  };

  const handleGotoDashboardClick = () => {
    if (newReportId) {
      projectSettingsActions.setSelectedReport(projectId, surveyId, newReportId);
      const pathname = `/one/projects/${projectId}/surveys/${surveyId}/reports/${newReportId}`;
      history.push(pathname);
    }
  };

  return (
    <div>
      <Button
        color={'secondary'}
        outline
        size={'sm'}
        onClick={toggleModal}
        className='mr-2'
        id={'tooltip_autogenerate_tables'}
      >
        <i className={`far fa-robot ${isAssistantRunning ? 'fa-beat-fade' : ''}`} />
      </Button>
      <UncontrolledTooltip target={'tooltip_autogenerate_tables'} delay={{ hide: 500 }} autohide={false}>
        {intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.assistant-button-tooltip' })}
      </UncontrolledTooltip>
      <Modal toggle={toggleModal} isOpen={isModalOpen} size={'lg'} className='tabulation-assistant-modal'>
        <ModalHeader>
          <i className={`fal fa-robot ${isAssistantRunning ? 'fa-beat-fade' : ''} mr-2`} />
          <span>{intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.tabulation-assistant' })}</span>
        </ModalHeader>
        <ModalBody>
          <p>{intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.modal-text' })}</p>
          {/* todo: Add question selection here! */}
          <Row>
            <Col md={5} lg={4} xl={3} className={'d-flex justify-content-between'}>
              {intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.folder-name' })}
            </Col>
            <Col md={7} lg={8} xl={9} className={'d-flex justify-content-between'}>
              <span className={'flex-fill'}>
                <Input
                  id={'foldername'}
                  type={'text'}
                  name={'foldername'}
                  autoComplete='off'
                  placeholder={folderNamePlaceholder}
                  value={folderName}
                  onChange={e => setFolderName(e.target.value)}
                  onBlur={() => {}}
                />
                <label htmlFor='name'>
                  <span />
                </label>
              </span>
            </Col>
          </Row>
          <ColorScale
            colorSchema={selectedColorScheme}
            onChange={e => setSelectedColorScheme(e.target.value)}
            lg={8}
            xl={9}
          />
          {!isAssistantRunning && !isAssistantFinished && (
            <Button className='mt-4' color={'primary'} onClick={handleRunAssistant} disabled={readonly}>
              {intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.start-assistant' })}
            </Button>
          )}
          {(isAssistantRunning || isAssistantFinished) && (
            <div className='mt-2'>
              {assistantStatus > 0 && (
                <div className='mb-2 fw-bold'>
                  Step: {assistantStatus} of {TOTAL_STEPS_COUNT}
                </div>
              )}
              <div>
                <AssistantStatusIcon currentStatus={assistantStatus} goalStatus={1} hasError={hasError} />
                <span>{intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.creating-new-folder' })}</span>
              </div>
              <div className='loading-bar-container'>
                <AssistantStatusIcon currentStatus={assistantStatus} goalStatus={2} hasError={hasError} />
                <div>
                  {intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.creating-tables' })}{' '}
                  {assistantStatus < 2 ? 0 : tablesCount + 1}/{allQuestions.length}
                </div>
                <LoadingProgressBar current={tablesCount} total={allQuestions.length - 1} />
              </div>
              <div>
                <AssistantStatusIcon currentStatus={assistantStatus} goalStatus={3} hasError={hasError} />
                <span>{intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.creating-new-report' })}</span>
              </div>
              <div className='loading-bar-container'>
                <AssistantStatusIcon currentStatus={assistantStatus} goalStatus={4} hasError={hasError} />
                <div>
                  {intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.creating-charts' })}{' '}
                  {assistantStatus < 4 ? 0 : chartsCount + 1}/{allQuestions.length}
                </div>
                <LoadingProgressBar current={chartsCount} total={allQuestions.length - 1} />
              </div>
            </div>
          )}
          {!hasError && isAssistantFinished && (
            <div className='my-4' style={{ fontSize: '1.25rem' }}>
              {intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.all-done-your-dashboard-is-ready' })}
            </div>
          )}
          {!hasError && isAssistantFinished && (
            <div className='mt-2'>
              <Button onClick={handleGotoDashboardClick} color={'primary'}>
                {intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.go-to-dashboard' })}
              </Button>
            </div>
          )}
          {hasError && (
            <div>
              {intl.formatMessage({ id: 'smoove.page.tables.tabulation-assistant.oh-no-something-went-wrong' })}
            </div>
          )}
          {isAssistantRunning && (
            <div className='mt-2'>
              {intl.formatMessage({
                id: 'smoove.page.tables.tabulation-assistant.please-wait-until-your-dashboard-has-been-creacted'
              })}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} color={'grey'} outline>
            {intl.formatMessage({ id: 'smoove.common.buttons.close' })}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const AssistantStatusIcon = ({ currentStatus, goalStatus, hasError }) => {
  if (hasError && currentStatus === goalStatus)
    return (
      <span className='mr-2'>
        <i className='fal fa-fw fa-xmark text-danger' />
      </span>
    );
  if (currentStatus < goalStatus)
    return (
      <span className='mr-2'>
        <i className={`fal fa-fw ${!hasError ? 'fa-turn-down-right' : 'fa-xmark text-danger'} `} />
      </span>
    );
  if (currentStatus === goalStatus)
    return (
      <span className='mr-2'>
        <i className={`fal fa-fw ${!hasError ? 'fa-cog fa-spin text-warning' : 'fa-xmark text-danger'} `} />
      </span>
    );
  if (currentStatus > goalStatus)
    return (
      <span className='mr-2'>
        <i className='fal fa-fw fa-check text-primary' />
      </span>
    );
  return <span></span>;
};

const LoadingProgressBar = ({ current, total }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const newPercentage = (current / total) * 100;
    setPercentage(newPercentage);
  }, [current, total]);

  const progressBarStyle = {
    height: '8px',
    background: `linear-gradient(to right, #8aa91b ${percentage}%, #e5e5e5 ${percentage}%)`
  };

  return (
    <div
      className='tabulation-loading-progress-bar'
      style={{ height: '8px', backgroundColor: 'lightgray', width: '100%' }}
    >
      <div style={progressBarStyle}></div>
    </div>
  );
};
