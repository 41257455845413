import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import { arrayMoveImmutable } from 'array-move';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';

import { projectSettingsActions } from 'module-smoove/src/smoove/redux/actions';
import { reportsActions } from 'smv-redux';

import { SmvCustomDropdown, ExportFileButtonAndModal } from '../misc';
import { EditPageButtonAndModal, DeletePageButtonAndModal, AddPageButtonAndModal } from './pages';
import { GridWithSize } from './pages';
import { StaticPageFilterButtonAndModal } from './pages/StaticPageFilters';

import './Report.scss';

export const Report = ({
  surveyId,
  report,
  reportsHandler,
  previewSize,
  setPreviewSize,
  activeSplits,
  activeFilters,
  readonly = false,
  reportRef,
  selectedPageId,
  sharedReportView = false,
  accessToken
}) => {
  const pages = report.pages;
  const history = useHistory();
  const params = useParams();
  const { projectid, surveyid, reportid, pageid } = params;

  useEffect(() => {
    if (!sharedReportView) {
      if (!pageid && selectedPageId) {
        const pathname = `/one/projects/${projectid}/surveys/${surveyid}/reports/${reportid}/pages/${selectedPageId}`;
        history.push(pathname);
      } else if (!pageid && !selectedPageId && pages?.order?.length > 0) {
        const pathname = `/one/projects/${projectid}/surveys/${surveyid}/reports/${reportid}/pages/${pages.order[0]}`;
        history.push(pathname);
      }
    }
  }, [projectid, surveyid, reportid, pageid, selectedPageId, history, pages.order, sharedReportView]);

  const [pagesOrder, setPagesOrder] = useState([]);

  useEffect(() => {
    if (pages?.order) {
      setPagesOrder(pages.order);
    }
  }, [pages?.order]);

  const onDragEnd = result => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }
    const { source, destination, draggableId } = result;

    const newOrder = arrayMoveImmutable(pagesOrder, source.index, destination.index);
    setPagesOrder(newOrder);
    reportsActions.updateReportPage(report.id, draggableId, { position: destination.index });
  };

  const pageHeads = pagesOrder.map((pageid, index) => {
    if (!pages.list?.[pageid]) {
      return null;
    }

    return (
      <MakeDraggable key={pageid} draggableId={pageid} index={index} isDragDisabled={readonly}>
        <TabHead
          page={pages.list[pageid]}
          pages={pages}
          reportsHandler={reportsHandler}
          readonly={readonly}
          isActive={pageid === params.pageid}
          sharedReportView={sharedReportView}
          accessToken={accessToken}
          activeFilters={activeFilters}
        />
      </MakeDraggable>
    );
  });

  return (
    <div className='page-tabs-container' ref={reportRef} id='reports-page-tabs-container'>
      <div className='smv-tabs-container'>
        <DragDropContext onDragEnd={onDragEnd} isDropDisabled={readonly}>
          <div className='d-flex tabs-container__sticky'>
            <MakeDroppable
              droppableId='page-tab-item-droppable'
              direction='horizontal'
              className='smv-tabs-container__tab-nav'
            >
              {pageHeads}
            </MakeDroppable>
            {!readonly && <AddPageButtonAndModal reportsHandler={reportsHandler} />}
          </div>
        </DragDropContext>

        <Row>
          <Col sm='12'>
            {pageid && (
              <GridWithSize
                surveyId={surveyid}
                report={report}
                reportsHandler={reportsHandler}
                pageid={pageid}
                previewSize={previewSize}
                setPreviewSize={setPreviewSize}
                activeSplits={activeSplits}
                activeFilters={activeFilters}
                readonly={readonly}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const TabHead = ({
  page = {},
  pages,
  reportsHandler,
  readonly,
  isActive,
  sharedReportView,
  accessToken,
  activeFilters
}) => {
  const history = useHistory();
  const params = useParams();
  const { projectid, surveyid, reportid } = params;

  const intl = useIntl();

  const exportHandler = useCallback(
    (filetype, exportLoopFilters = null) => reportsHandler.exportPage(reportid, page.id, filetype, exportLoopFilters),
    [reportsHandler, reportid, page.id]
  );

  const handleSelectPage = useCallback(
    pageid => {
      projectSettingsActions.setSelectedPage(projectid, surveyid, reportid, pageid);
      let pathname = `/one/projects/${projectid}/surveys/${surveyid}/reports/${reportid}/pages/${pageid}`;
      if (sharedReportView) {
        pathname = `/one/shares/reports/${reportid}/${accessToken}/${pageid}`;
      }
      history.push(pathname);
    },
    [history, accessToken, projectid, surveyid, reportid, sharedReportView]
  );

  const handleCopyPage = useCallback(() => {
    reportsActions.duplicateReportPage(reportid, page.id).then(res => {
      const newPageId = res.pages.order[res.pages.order.length - 1];
      handleSelectPage(newPageId);
    });
  }, [page.id, reportid, handleSelectPage]);

  const pageTitle = page.name && page.name?.length > 0 ? page.name : 'untitled page';

  return (
    <div
      className={classnames('smv-tabs-container__tab-head nav-item text-dark tab-head', {
        'smv-tabs-container__tab-head--active': isActive
      })}
    >
      <div className='d-flex text-dark p-2' onClick={() => handleSelectPage(page.id)}>
        <div
          className={classnames({ 'smv-tabs-container__tab-title mr-2': true, 'font-italic': !page.name })}
          title={pageTitle}
        >
          {pageTitle}
        </div>
      </div>
      {!readonly && (
        <div className='p-2'>
          <SmvCustomDropdown persist={true}>
            <DropdownItem>
              <EditPageButtonAndModal page={page} reportsHandler={reportsHandler} />
            </DropdownItem>
            <DropdownItem>
              <StaticPageFilterButtonAndModal page={page} />
            </DropdownItem>
            <DropdownItem>
              <div className={`smv-custom-dropdown__buttons`} onClick={handleCopyPage}>
                <i className={'fal fa-copy icon-smv-blue mr-1'} />
                <span>{intl.formatMessage({ id: 'smoove.page.reports.duplicate-page' })}</span>
              </div>
            </DropdownItem>
            <DropdownItem>
              <ExportFileButtonAndModal
                type={'page'}
                exportHandler={exportHandler}
                reportId={reportid}
                activeFilters={activeFilters}
              />
            </DropdownItem>
            {pages.order.length > 1 && (
              <>
                <DropdownItem divider />
                <DropdownItem>
                  <DeletePageButtonAndModal reportsHandler={reportsHandler} page={page} />
                </DropdownItem>
              </>
            )}
          </SmvCustomDropdown>
        </div>
      )}
      {sharedReportView && (
        <div className='p-2'>
          <SmvCustomDropdown persist={true}>
            <DropdownItem>
              <ExportFileButtonAndModal
                type={'page'}
                exportHandler={exportHandler}
                reportId={reportid}
                activeFilters={activeFilters}
              />
            </DropdownItem>
          </SmvCustomDropdown>
        </div>
      )}
    </div>
  );
};

const getDraggableStyles = isDragging => {
  return {
    boxSizing: 'border-box',
    background: 'white',
    boxShadow:
      isDragging && '0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)'
  };
};

const MakeDraggable = ({ children, draggableId, index, className, isDragDisabled }) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={className}
          style={{
            ...provided.draggableProps.style,
            ...getDraggableStyles(snapshot.isDragging)
          }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

const MakeDroppable = ({ children, droppableId, isDropDisabled, direction, className }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled} direction={direction}>
      {provided => (
        <div {...provided.droppableProps} ref={provided.innerRef} className={className}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
