import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonGroup,
  Col,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { customAlphabet } from 'nanoid';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { isArray } from 'lodash';

import { reorderArray } from 'smv-helpers/reorderArray';

import { DynamicSplitConfigAddElements } from './DynamicSplitConfigAddElements';
import { DynamicSplitConfigSettings } from './DynamicSplitConfigSettings';

import './DynamicSplitConfig.scss';

export const DynamicSplitConfig = ({ report, selectedSplit, internalSplitList, handler }) => {
  const [openedSplitElement, setOpenedSplitElement] = useState(null);

  const split = useMemo(() => internalSplitList?.list?.[selectedSplit], [selectedSplit, internalSplitList]);

  const elements = useSelector(state => state.survey.questionnaire.list ?? {});
  const elementsSysvar = useSelector(state => state.survey?.systemVariables?.list ?? {});
  const elementsCalcvar = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const dismiss = useCallback(() => {
    // @todo: reset changes
    handler.setSelectedSplit(null);
  }, [handler]);

  const save = useCallback(
    split => {
      // @todo: reset changes
      handler.saveSplit(split);
      handler.setSelectedSplit(null);
    },
    [handler]
  );

  const internalHandler = useMemo(
    () => ({
      setSplitLabel: e => {
        const { value } = e.target;
        handler.setInternalSplitList(state =>
          produce(state, draft => {
            draft.list[split.id].label = value;
          })
        );
      },
      addSplitElement: (sourceId, sourceType, targetid) => {
        const splitelementid = customAlphabet('1234567890abcdef', 24)();

        if (targetid) {
          handler.setInternalSplitList(state =>
            produce(state, draft => {
              draft.list[split.id].elements.list[splitelementid] = {
                id: splitelementid,
                sourceId: sourceId,
                sourceType: sourceType
              };
              const targetIndex = draft.list[split.id].elements.order.indexOf(targetid) ?? 0;
              draft.list[split.id].elements.order.splice(targetIndex, 0, splitelementid);
              draft.list[split.id].elements.list[splitelementid].config = {
                sortation: {
                  headSortationMode: null,
                  manualHeadSortation: {
                    order: [] // could already set the item order here as done in sourceElementValues in SplitElementsItems?
                  }
                },
                hidden: {}
              };
            })
          );
        } else {
          handler.setInternalSplitList(state =>
            produce(state, draft => {
              if (isArray(draft.list[split.id].elements.list)) {
                draft.list[split.id].elements.list = {};
              }
              draft.list[split.id].elements.list[splitelementid] = {
                id: splitelementid,
                sourceId: sourceId,
                sourceType: sourceType
              };
              draft.list[split.id].elements.order.push(splitelementid);
              draft.list[split.id].elements.list[splitelementid].config = {
                sortation: {
                  headSortationMode: null,
                  manualHeadSortation: {
                    order: [] // could already set the item order here as done in sourceElementValues in SplitElementsItems?
                  }
                },
                hidden: {}
              };
            })
          );
        }
      },
      saveSplitElement: splitelement => {
        handler.setInternalSplitList(state =>
          produce(state, draft => {
            draft.list[split.id].elements.list[splitelement.id] = splitelement;
          })
        );
      },
      deleteSplitElement: splitelementid => {
        // todo:
        handler.setInternalSplitList(state =>
          produce(state, draft => {
            delete draft.list[split.id].elements.list[splitelementid];
            draft.list[split.id].elements.order = draft.list[split.id].elements.order.filter(
              id => id !== splitelementid
            );
          })
        );
        setOpenedSplitElement(null);
      },
      setOpenedSplitElement: splitelementid => {
        setOpenedSplitElement(splitelementid);
      },
      reorderSplitElement: newOrder => {
        handler.setInternalSplitList(state =>
          produce(state, draft => {
            draft.list[split.id].elements.order = newOrder;
          })
        );
      }
    }),
    [handler, split.id]
  );

  const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "#cccccc" : "#e5e5e5",
    // background: '#fafafa',
    // border: '1px solid #ebebeb',
    minHeight: '100px'
  });

  const onDragEnd = result => {
    const sourceId = result.draggableId;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // sorting inside the list
    if (result.source.droppableId === result.destination.droppableId) {
      const newOrder = reorderArray(split?.elements?.order, result.source.index, result.destination.index);
      internalHandler.reorderSplitElement(newOrder);
    } else {
      // adding new element into list
      if (result.destination.droppableId === 'split-selected-elements-list-droppable') {
        let newPosition = null;

        let type = '';
        if (!isArray(elements) && Object.keys(elements).includes(sourceId)) {
          type = 'question';
        }
        if (!isArray(elementsSysvar) && Object.keys(elementsSysvar).includes(sourceId)) {
          type = 'sysvar';
        }
        if (isArray(elementsCalcvar) && elementsCalcvar.map(el => el.id).includes(sourceId)) {
          type = 'calcvar';
        }

        if (split.elements?.order?.length > 0) {
          newPosition = split.elements.order?.[result.destination.index];
        }
        internalHandler.addSplitElement(sourceId, type, newPosition);
      }
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // userSelect: 'none',
    // minHeight: '42px',
    // background: isDragging ? "#8aa91b" : "#e5e5e5",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  return (
    <>
      <ModalHeader toggle={handler.close}>
        <FormattedMessage id={'smoove.page.common.dynamic-splits.label'} defaultMessage={'Splitting'} />
      </ModalHeader>
      <ModalBody>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Col md={6} className={'d-flex flex-column'}>
              <Row>
                <Col>
                  <h6>
                    <FormattedMessage
                      id={'smoove.page.common.dynamic-splits.split-configuration'}
                      defaultMessage={'Splits Configuration'}
                    />
                  </h6>
                </Col>
              </Row>
              <Row className='mt-1 align-items-center'>
                <Label for='splitLabel' className='fw-bold' md={5}>
                  <FormattedMessage
                    id={'smoove.page.common.dynamic-splits.split-label'}
                    defaultMessage={'Split label'}
                  />
                </Label>
                <Col md={7}>
                  <Input
                    type='text'
                    id={`splitLabel`}
                    name='label'
                    value={split?.label ?? ''}
                    placeholder='Enter split label'
                    onChange={internalHandler.setSplitLabel}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} className='mt-2'>
                  <h6>
                    <FormattedMessage
                      id={'smoove.page.common.dynamic-splits.split-elements'}
                      defaultMessage={'Split Elements'}
                    />
                  </h6>

                  <Droppable droppableId='split-selected-elements-list-droppable'>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        className='droppable-default'
                      >
                        {split?.elements?.order?.map((splitelementid, index) => {
                          const splitelement = split?.elements?.list?.[splitelementid];

                          if (!splitelement) return null;

                          let label, sourceElement;
                          if (splitelement.sourceType === 'question') {
                            sourceElement = elements[splitelement.sourceId];
                            label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.title;
                          } else if (splitelement.sourceType === 'sysvar') {
                            sourceElement = systemVariables.list[splitelement.sourceId];
                            label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.label ?? '';
                          } else if (splitelement.sourceType === 'calcvar') {
                            sourceElement = elementsCalcvar?.find(el => el.id === splitelement.sourceId);
                            label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.label ?? '';
                          }

                          return (
                            <Draggable key={splitelementid} draggableId={splitelementid} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`draggable-default dynamic-split-list-element ${
                                    openedSplitElement === splitelementid ? 'dynamic-split-list-element--active' : ''
                                  }`}
                                  key={splitelementid}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                  <i className='fal fa-grip-vertical ml-2'></i>
                                  <span className='dynamic-split-list-element__label mx-2'>{label}</span>
                                  <ButtonGroup size={'sm'}>
                                    <Button
                                      id={'edit-split-' + splitelementid}
                                      outline={openedSplitElement === splitelementid ? false : true}
                                      color={'secondary'}
                                      onClick={() => {
                                        if (openedSplitElement === splitelementid) {
                                          internalHandler.setOpenedSplitElement(null);
                                        } else {
                                          internalHandler.setOpenedSplitElement(splitelementid);
                                        }
                                      }}
                                    >
                                      <i className='fal fa-edit' />
                                    </Button>
                                    <UncontrolledTooltip target={'edit-split-' + splitelementid}>
                                      <FormattedMessage
                                        id={'smoove.page.common.dynamic-splits.edit-split'}
                                        defaultMessage={'Edit split'}
                                      />
                                    </UncontrolledTooltip>
                                    <Button
                                      id={'delete-split-' + splitelementid}
                                      outline={true}
                                      color={'danger'}
                                      onClick={() => internalHandler.deleteSplitElement(splitelementid)}
                                    >
                                      <i className='fal fa-trash-alt' />
                                    </Button>
                                    <UncontrolledTooltip target={'delete-split-' + splitelementid}>
                                      <FormattedMessage
                                        id={'smoove.page.common.dynamic-splits.delete-split'}
                                        defaultMessage={'Delete split'}
                                      />
                                    </UncontrolledTooltip>
                                  </ButtonGroup>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Col>
              </Row>
            </Col>

            {!openedSplitElement && (
              <DynamicSplitConfigAddElements handler={internalHandler} report={report} split={split} />
            )}
            {!!openedSplitElement && (
              <DynamicSplitConfigSettings
                handler={internalHandler}
                splitelement={split.elements.list?.[openedSplitElement]}
              />
            )}
          </Row>
        </DragDropContext>
      </ModalBody>
      <ModalFooter>
        <Button color='grey' onClick={dismiss}>
          <FormattedMessage id={'smoove.common.buttons.dismiss'} defaultMessage={'Dismiss'} />
        </Button>
        <Button color='primary' onClick={() => save(split)} disabled={!split.label}>
          <FormattedMessage id={'smoove.common.buttons.save'} defaultMessage={'Save'} />
        </Button>
      </ModalFooter>
    </>
  );
};
