import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, UncontrolledTooltip } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import produce from 'immer';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { reportsActions } from 'smv-redux';

import { MediaLibraryModal } from '../../questionnaire/Content/MediaLibrary/MediaLibraryButtonAndModal';
import { DashboardElementMenu } from '../DashboardElementMenu';

import { ExportFileButtonAndModal } from '../../misc';
import { getMediaElementType } from '../../questionnaire/Content/MediaLibrary/helpers/getMediaElementType';

import './DashboardElementMedia.scss';

export const DashboardElementMedia = ({ element, reportid, pageid, readonly, handler }) => {
  const media = useSelector(state => state.survey.media);
  const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

  const chartConfig = element.chartConfig;
  const mediaItems = element?.mediaConfig?.items ?? { list: {}, order: [] };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);

  const isSlideshow = element?.mediaConfig?.showAsSlideshow ?? false;

  const toggleModal = useCallback(e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(isModalOpen => !isModalOpen);
  }, []);

  const saveHandler = useCallback(
    config => {
      produce(element, draft => {
        draft.mediaConfig = config;
      });
      reportsActions.updateDashboardElementMediaConfig(reportid, pageid, element.id, config);
    },
    [element, pageid, reportid]
  );

  const selectFileCb = useCallback(
    (images, showAsSlideshow, mediaItems) => {
      const mediaConfig = produce(element.mediaConfig, draft => {
        const _items = { list: {}, order: [] };
        draft.showAsSlideshow = showAsSlideshow;

        images.forEach(img => {
          _items.order.push(img.id);
          _items.list[img.id] = {
            ...(draft?.items?.list?.[img.id] ?? {
              mediaType: getMediaElementType(img),
              source: img.id,
              lightbox: true,
              title: null,
              subtitle: null
            }),
            ...(mediaItems.list?.[img.id] ?? {})
          };
        });

        draft.items = _items;
      });

      saveHandler(mediaConfig);
    },
    [saveHandler, element.mediaConfig]
  );

  const chartTitle = chartConfig?.title?.length > 0 ? chartConfig?.title : 'Element title';
  const chartSubtitle = chartConfig?.subTitle ?? null;

  const isSingleItem = useMemo(() => mediaItems?.order?.length === 1, [mediaItems?.order?.length]);

  const containerAlignmentClasses = classNames({
    'dashboard-image-container': true,
    'flex-column': element?.mediaConfig?.layout === 'column',
    'flex-row': isSingleItem || element?.mediaConfig?.layout === 'row' || !element?.mediaConfig?.layout
  });

  const mediaItemAlignmentClasses = classNames({
    'align-items-start': element?.mediaConfig?.verticalAlign === 'top',
    'align-items-center': element?.mediaConfig?.verticalAlign === 'center',
    'align-items-end': element?.mediaConfig?.verticalAlign === 'bottom',
    'justify-content-start': element?.mediaConfig?.horizontalAlign === 'left',
    'justify-content-center': element?.mediaConfig?.horizontalAlign === 'center',
    'justify-content-end': element?.mediaConfig?.horizontalAlign === 'right'
  });

  const handleLightboxClick = useCallback((mediaid = null) => setLightboxImage(mediaid), []);
  const handleImageClick = useCallback(
    mediaid => {
      const mediaConfig = element?.mediaConfig?.items?.list?.[mediaid];
      const clickBehavior = mediaConfig?.clickBehavior ?? 'lightbox';

      if (clickBehavior === 'lightbox') setLightboxImage(mediaid);
      else if (clickBehavior === 'link') window.open(mediaConfig?.linkTarget ?? '', '_blank');
    },
    [element?.mediaConfig]
  );
  const exportOptions = useMemo(() => {
    return {
      pptx: true,
      image: false,
      xlsx: false
    };
  }, []);

  const exportHandler = useCallback(filetype => handler.exportElement(element.id, filetype), [handler, element.id]);

  useEffect(() => {
    // this fixes an issue with the display of single items after changing the number of elements to one or the mediaConfig
    if (isSingleItem) {
      const mediaConfig = produce(element?.mediaConfig, draft => {
        draft.layout = 'row';
        draft.align = 'center';
      });
      if (!_.isEqual(mediaConfig, element?.mediaConfig)) {
        saveHandler(mediaConfig);
      }
    }
    // saveHandler & element?.mediaConfig not added to dependency array because they would reset the changes again. We only want to reset alignment once if there is exaclty one image available
    // eslint-disable-next-line
  }, [isSingleItem]);

  if (!element) return <div>missing element</div>;

  return (
    <Card
      className={classNames({
        'h-100 w-100 smv-dashboard-element smv-dashboard-element--media': true,
        'smv-dashboard-element--readonly': readonly
      })}
    >
      <CardHeader>
        <CardTitle tag='h5'>{chartTitle}</CardTitle>
        <CardSubtitle tag='h6' className='mb text-muted'>
          <small>
            <i>{chartSubtitle}</i>
          </small>
        </CardSubtitle>
        {/* todo: add image export functionality and set exportOptions.image to true */}
        {!readonly && (
          <DashboardElementMenu
            handler={handler}
            exportHandler={exportHandler}
            element={element}
            toggleMediaLibrary={toggleModal}
            exportOptions={exportOptions}
          />
        )}
        {readonly && (
          <div
            id={`tt_export-dashboard-element-single-export-button_${element.id}`}
            className={'dashboard-element-single-export-button'}
          >
            <ExportFileButtonAndModal
              type={'element'}
              exportHandler={exportHandler}
              exportOptions={exportOptions}
              iconOnly={true}
            />
            <UncontrolledTooltip
              target={`tt_export-dashboard-element-single-export-button_${element.id}`}
              delay={{ hide: 500, show: 500 }}
              autohide={false}
            >
              <FormattedMessage id={'smoove.page.reports.export-element'} defaultMessage={'Export Image'} />
            </UncontrolledTooltip>
          </div>
        )}
      </CardHeader>
      <CardBody>
        {/* {!readonly && <MediaAlignmentButtons element={element} saveHandler={saveHandler} isSingleItem={isSingleItem} />} */}

        {element.loading && (
          <div className='smv-dashboard-element__loading-backdrop'>
            <i className='fas fa-spinner fa-spin' />
          </div>
        )}
        {mediaItems?.order?.length <= 0 && (
          <div className='dashboard-image__placeholder' onClick={toggleModal}>
            <i className='far fa-photo-film fa-2x' />
            <span>Select file(s)</span>
          </div>
        )}

        {!isSlideshow && (
          <div className={containerAlignmentClasses}>
            {mediaItems?.order?.map((mediaid, idx) => {
              const mediaItem = mediaItems.list?.[mediaid];
              const file = media?.[mediaItem.source] ?? null;
              if (!file) return null;
              const url = `${baseUrl}/${file.url}`;

              const fileType = getMediaElementType(file);

              switch (fileType) {
                case 'image':
                  return (
                    <div
                      key={`${mediaid}_${idx}`}
                      onClick={() => handleImageClick(mediaid)}
                      className={'single-image-container ' + mediaItemAlignmentClasses}
                      style={{
                        height:
                          (element?.mediaConfig?.layout === 'column' ? 100 / mediaItems?.order?.length : '100') + `%`
                      }}
                    >
                      <img className='dashboard-image' src={url} alt={file.originalName} loading='lazy' />
                    </div>
                  );
                case 'audio':
                  return (
                    <div
                      key={`${mediaid}_${idx}`}
                      className={`av-media d-flex p-1 ${mediaItemAlignmentClasses}`}
                      style={{
                        height:
                          (element?.mediaConfig?.layout === 'column' ? 100 / mediaItems?.order?.length : '100') + `%`
                      }}
                    >
                      <audio controls title={file.originalName}>
                        <source src={url} type={file.mimeType} />
                      </audio>
                    </div>
                  );
                case 'video':
                  return (
                    <div
                      key={`${mediaid}_${idx}`}
                      className={`av-media d-flex p-1 ${mediaItemAlignmentClasses}`}
                      style={{
                        height:
                          (element?.mediaConfig?.layout === 'column' ? 100 / mediaItems?.order?.length : '100') + `%`
                      }}
                    >
                      <video controls>
                        <source src={url} type={file.mimeType} />
                      </video>
                    </div>
                  );
                default:
                  return <div></div>;
              }
            })}
          </div>
        )}

        {isSlideshow && <DashboardImageCarousel mediaItems={mediaItems} handleImageClick={handleImageClick} />}

        {!!lightboxImage && (
          <Lightbox
            mainSrc={`${baseUrl}/${media?.[lightboxImage].url}`}
            onCloseRequest={() => handleLightboxClick()}
            style={{ zIndex: '2000' }}
            className='test'
          />
        )}
        {isModalOpen && (
          <MediaLibraryModal
            toggle={toggleModal}
            isOpen={isModalOpen}
            writeable={true}
            selectFileCb={selectFileCb}
            prevSelectedFiles={mediaItems?.order ?? []}
            mediaItems={mediaItems}
            slideshowSettings={{ isSlideshow: isSlideshow }}
          />
        )}
      </CardBody>
    </Card>
  );
};

const DashboardImageCarousel = ({ mediaItems, handleImageClick }) => {
  const media = useSelector(state => state.survey.media);
  const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

  if (!media || !mediaItems) return <div></div>;

  const items = mediaItems.order.map((mediaid, idx) => {
    const file = media?.[mediaid];
    if (!file) return null;
    const fileType = getMediaElementType(file);
    const url = `${baseUrl}/${file.url}`;

    switch (fileType) {
      case 'image':
        return (
          <div
            key={`${mediaid}_${idx}`}
            onClick={() => handleImageClick(mediaid)}
            className='slider-item slider-item--image'
          >
            <img className='dashboard-image' src={url} alt={file.originalName} loading='lazy' />
          </div>
        );
      case 'audio':
        return (
          <div
            key={`${mediaid}_${idx}`}
            className='slider-item slider-item--audio av-media d-flex flex-column align-items-center justify-content-center'
          >
            <audio controls title={file.originalName}>
              <source src={url} type={file.type} />
            </audio>
          </div>
        );
      case 'video':
        return (
          <div
            key={`${mediaid}_${idx}`}
            className='slider-item slider-item--video av-media d-flex flex-column align-items-center justify-content-center'
          >
            <video controls>
              <source src={url} type={file.type} />
            </video>
          </div>
        );
      default:
        return <div></div>;
    }
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <Slider {...settings} className='dashboard-carousel'>
      {items}
    </Slider>
  );
};
